<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          {{ $store.getters.onScreen_cliente_nome }} - Revisões
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"
            class="ma-2"
            fab
            small
            color="indigo"
            dark
            @click.native="novoDevice"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        Adicionar parâmetro
      </v-tooltip> -->

      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-spacer></v-spacer>

    <!-- Area de detalhes do dispositivo -->

    <!-- <v-alert type="warning">
      
      <v-row align="center">
      <v-col class="grow">
        Veículo atingiu kilometragem para manutenção preventiva - TROCA DE ÓLEO
      </v-col>
      <v-col class="shrink">
        <v-btn>Informar</v-btn>
      </v-col>
      </v-row>

    </v-alert> -->

    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
      <!-- Area de botoes de controle -->
      <v-layout wrap="wrap">
        <!-- ADICIONAR REGISTRO -->
        <v-btn color="blue-grey" class="ma-2 white--text" @click.native="novo">
          Novo
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>

        <!-- UPLOAD -->
        <v-btn
          :loading="loading3"
          :disabled="loading3"
          color="blue-grey"
          class="ma-2 white--text"
          @click="loader = 'loading3'"
        >
          Upload
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </v-layout>

      <v-spacer></v-spacer>

      <v-layout wrap="wrap">
        <v-flex xs12="xs12" md12="md12">
          <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
          >
            <template v-slot:header>
              <v-toolbar class="mb-1" elevation="1">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Pesquisar"
                ></v-text-field>

                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="sortBy"
                    flat
                    solo-inverted
                    hide-details
                    :items="keys"
                    prepend-inner-icon="mdi-magnify"
                    label="Ordenar por"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-btn-toggle v-model="sortDesc" mandatory>
                    <v-btn large depressed color="blue" :value="false">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn large depressed color="blue" :value="true">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.nome"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card
                    v-ripple
                    class="clickable"
                    style="cursor:pointer;"
                    @click.native="selectItem(item)"
                  >
                    <v-card-text>
                      <v-list-item class="grow">
                        <v-list-item-icon>
                          <v-icon color="gray">
                            mdi-car-cruise-control
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            ><h4>{{ item.detail.nome }}</h4></v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            item.detail.km
                          }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-spacer></v-spacer> -->

                        <v-menu auto offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                              v-on:click.prevent
                              @click.stop=""
                              @mousedown.stop=""
                              @touchstart.stop=""
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(itemMenu, i) in menus"
                              :key="i"
                              @click.stop="clickContextMenu(itemMenu, item)"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  itemMenu.text
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row class="mt-2" align="center" justify="center">
                <span class="grey--text">Itens por pagina</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      text
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span
                  class="mr-4
                  grey--text"
                >
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                  fab
                  small
                  dark
                  color="indigo"
                  class="mr-1"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  dark
                  color="indigo"
                  class="ml-1"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  data: () => ({
    gprs: [],
    data_coleta: null,
    odometro_formatado: null,
    centered: {},
    markers: [],
    vin_number: null,
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) => v.length <= 25 || "Input too long!",
    pagination: {},
    headers: [
      {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Iron (%)", value: "iron" },
    ],
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%",
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%",
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%",
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%",
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%",
      },
      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%",
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%",
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%",
      },
    ],
    menus: [
      { text: "Editar", route: "/link1", icon: "mdi-home" },
      { text: "Remover", route: "/link2", icon: "mdi-note" },
    ],
    items: [],
    itemsPerPageArray: [4, 8, 12],
    itemsDropdown: [{ title: "Lista geral de revisão" }],
    search: "",
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 4,
    sortBy: "id",
    keys: ["id", "id_cliente"],
    loader: null,
    loading3: false,
  }),
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    showLocationOnTheMap(latitude, longitude) {
      console.log("LATITUDE", latitude);
      console.log("LONGITUDE", longitude);

      var positions = [[latitude, longitude]];

      var positionsDD = [];

      for (var i = 0; i < positions.length; i++) {
        var latDD = parseFloat(
          parseFloat(positions[i][0][0].substr(0, 2)) +
            parseFloat(positions[i][0][0].substr(2, 8)) / 60
        ).toFixed(6);
        var lonDD = parseFloat(
          parseFloat(positions[i][1][0].substr(0, 3)) +
            parseFloat(positions[i][1][0].substr(3, 8)) / 60
        ).toFixed(6);

        latDD = positions[i][0][1] == "S" ? latDD * -1 : latDD;
        lonDD =
          positions[i][1][1] == "W" || positions[i][1][1] == "O"
            ? lonDD * -1
            : lonDD;

        positionsDD.push([latDD, lonDD]);
      }

      this.centered = { lat: parseFloat(latDD), lng: lonDD };
      this.$store.commit("storeMapCenter", this.centered);
      this.markers = [
        {
          position: { lat: parseFloat(latDD), lng: lonDD },
          label: "Carro do Rodrigo",
        },
      ];

      console.log("CENTERED", this.centered);
      console.log("MARKERS", this.markers);
    },
    trackingHistory() {
      this.$router.push({ name: "historicoTracking" });
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    close() {
      console.log("Dialog closed");
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    novo() {
      this.$store.commit("storeClienteKmParam", null);
      this.$router.push({ name: "cadastroParametroKM" });
    },
    selectItem(item) {
      console.log("SELECTED", item);
    },
    clickEditMenu(item) {
      item["modo"] = "alt";
      console.log("Chegou edit", item);

      this.$store.commit("storeClienteKmParam", item);
      this.$router.push({ name: "cadastroParametroKM" });
    },
    clickDeleteMenu(item) {
      console.log("clickDeleteMenu", item);

      this.$http
        .delete(process.env.VUE_APP_ENDPOINT_ROOT + "/controle/km/" + item.id)
        .then((response) => {
          this.ins_response = response;
          this.snackbar_text = "Removido com sucesso";
          this.snackbar = true;
          this.$router.go(0);
        })
        .catch((error) => console.log(error));
    },
    clickContextMenu(itemMenu, item) {
      console.log("ITEM MENU", itemMenu);
      console.log("ITEM", item);

      if (itemMenu.text == "Remover") {
        console.log("DELETE MENU", itemMenu.text);
        this.clickDeleteMenu(item);
      } else if (itemMenu.text == "Editar") {
        console.log("EDIT MENU", itemMenu.text);
        this.clickEditMenu(item);
      }
    },
    startLoad() {
      this.$http
        .get(
          process.env.VUE_APP_ENDPOINT_ROOT +
            "/controle/km/" +
            this.$store.getters.onScreen_cliente_id
        )
        .then((response) => {
          console.log("CREATED", response.data.clientes_data);
          this.items = response.data.clientes_data;
        });
    },
  },
  created() {
    this.startLoad();
  },
};
</script>
