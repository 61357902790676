<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          Serviços - Veículo {{ $store.getters.onScreen_device_id }}
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- <v-container fluid> -->
    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
      <!-- Area de botoes de controle -->
      <!-- <v-layout wrap="wrap">
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click.native="agendaDeServicos"
        >
          agendar
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-layout> -->

      <!-- <v-card>

          <v-card-title>Dados do Novo Dispositivo</v-card-title>

          <v-card-text>
      
              <v-form ref="form" v-model="valid" lazy-validation>

              <v-text-field
                  v-model="device_id"
                  :counter="14"
                  :rules="idRules"
                  label="Nro do Identificador"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="nro_fone"
                  :counter="10"
                  :rules="foneRules"
                  label="Nro Móvel do chip"
                  required
              ></v-text-field>

              <v-text-field class='placaInput'
                  v-model="placa_veiculo"
                  :counter="7"
                  :rules="placaRules"
                  label="Placa do veículo de instalação"
                  required
              ></v-text-field>

              </v-form>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color='success' @click.native="enviarCadastro()">
                  OK
              </v-btn>
              <v-btn color='error' @click.stop="$router.go(-1)">
                  Cancelar
              </v-btn>
          </v-card-actions>

      </v-card> -->

      <v-layout wrap="wrap">

        <v-flex xs12="xs12" md12="md12"> <!-- Agendamento de novo servico -->
          <v-card>

            <v-card-title>Dados do serviço</v-card-title>

            <v-card-text>
              <v-form v-model="valid">
                <!-- <v-container> -->
                  <v-row>
                    <v-col xs="12" md="4">
                      <v-text-field
                        v-model="txt_descricao_servico"
                        :counter="50"
                        label="Descrição do serviço"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col xs="12" md="4">
                      <v-text-field
                        v-model="txt_km_execucao"
                        :counter="10"
                        label="Km de execução"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col xs="12" md="4">
                      <v-text-field
                        v-model="txt_observacoes"
                        :counter="255"
                        label="Observações"
                        required
                      ></v-text-field>
                    </v-col>

                  </v-row>
                <!-- </v-container> -->
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click.native="enviarAgenda()">
                Agendar
              </v-btn>
              <v-btn color="primary" @click.native="enviarExecucao()">
                Executado
              </v-btn>
              <v-btn color="error" @click.stop="">
                Limpar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-flex>

      </v-layout>

      <v-layout wrap="wrap"> <!-- Timelines -->

        <v-flex xs12="xs12" md6="md6"> <!-- agendamentos futuros -->

          <v-card height="500px" class="scroll">

            <v-card-title>Próximos</v-card-title>

            <v-card-text>
                <v-timeline style="height:90%">

                  <v-timeline-item v-for='servicos_ag in servicos_agendados' :key='servicos_ag.id'>
                    <template v-slot:opposite>
                      <span>Agendado em {{servicos_ag.data_agenda}}</span>
                    </template>
                    <v-card class="elevation-2">
                      <v-card-title class="text-h6">
                        {{servicos_ag.descricao_servico}} - {{servicos_ag.km_agendada}}
                      </v-card-title>
                      <v-card-text >
                        {{servicos_ag.observacoes}}
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>

                </v-timeline>
            </v-card-text>

          </v-card>

        </v-flex>

        <v-flex xs12="xs12" md6="md6"> <!-- historico de servicos realizados -->

          <v-card height="500px" class="scroll">

            <v-card-title>Histórico</v-card-title>

            <v-card-text>
                <v-timeline clipped style="height:90%">

                  <v-timeline-item v-for='servicos_ex in servicos_executados' :key='servicos_ex.id'>
                    <template v-slot:opposite>
                      <span>{{servicos_ex.data_execucao}}</span>
                    </template>
                    <v-card class="elevation-2">
                      <v-card-title class="text-h6">
                        {{servicos_ex.descricao_servico}} - {{servicos_ex.km_execucao}}
                      </v-card-title>
                      <v-card-text >
                        {{servicos_ex.observacoes}}
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>

                </v-timeline>
            </v-card-text>

          </v-card>

        </v-flex>

      </v-layout>

      
    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="success">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<style>
  
  .placaInput input {
    text-transform: uppercase;
  }
  .v-timeline{
    height:200px;/* or any height you want */
    overflow-y:auto;
    overflow-x: hidden;
  }
  .scroll {
    overflow-y: scroll
  }

</style>

<script>
export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    valid: true,
    device_id: "",
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
    servicos_executados: [],
    servicos_agendados: [],
    servicos_executados_new: [
    {
      date:'2016-09-30',
      title:"Troca de correia 20000",
      description:"Tudo ok",
      category:"revisão",
      icon:"code",
      color:"blue",
    },
    {
      date:'2016-09-30',
      title:"Troca de correia 20000",
      description:"Tudo ok",
      category:"revisão",
      icon:"code",
      color:"red",
    }
    ],
    txt_descricao_servico: '',
    txt_km_execucao: '',
    txt_observacoes: ''
  }),
  methods: {
    enviarAgenda() {

      console.log('Chegou')

      this.payload = { device_id: this.$store.getters.onScreen_device_id, 
                       descricao_servico: this.txt_descricao_servico, 
                       data_agenda: new Date().toISOString().split('T')[0],
                       km_agendada: this.txt_km_execucao, 
                       observacoes: this.txt_observacoes 
      }

      console.log('PAYLOAD', this.payload)

      this.$http
        .post( process.env.VUE_APP_ENDPOINT_ROOT + '/device/' + this.$store.getters.onScreen_device_id + '/agenda', this.payload ) 
        .then( (response) => {
            this.ins_response = response
            this.snackbar_text = 'Agendamento efetuado'
            this.snackbar=true
          })
        .catch(error => console.log(error))

    },
    enviarExecucao() {

      console.log('Chegou')

      this.payload = { 
        id: 0, 
        device_id: this.$store.getters.onScreen_device_id, 
        descricao_servico: this.txt_descricao_servico, 
        data_execucao: new Date().toISOString().split('T')[0],
        km_execucao: this.txt_km_execucao, 
        observacoes: this.txt_observacoes 
      }

      console.log('PAYLOAD', this.payload)

      this.$http
        .put( process.env.VUE_APP_ENDPOINT_ROOT + '/device/' + this.$store.getters.onScreen_device_id + '/agenda', this.payload )
        .then( (response) => {
            this.ins_response = response
            this.snackbar_text = 'Cadastro efetuado'
            this.snackbar=true
          })
        .catch(error => console.log(error))

      this.$forceUpdate()

    },
    start_load() {

      this.$http
      .get(process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + this.$store.getters.onScreen_device_id + '/agenda')
      .then( (response) => {

        this.servicos_executados = response.data.servicos.executados
        this.servicos_agendados  = response.data.servicos.agendados

        console.log('AGENDA', this.servicos_agendados )
        console.log('EXECUCOES', this.servicos_executados )

      });
      
    },
  },
  computed: {},
  mounted() {

    this.start_load()

  },
};
</script>
