<template>
  <v-container fluid>

    <v-toolbar>

    <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
            mdi-arrow-left
        </v-icon>
    </v-app-bar-nav-icon>

    <template v-if="isEdicao">
      <v-toolbar-title><h4>Edição de usuário</h4></v-toolbar-title>
    </template>
    <template v-else>
      <v-toolbar-title><h4>Novo usuário</h4></v-toolbar-title>
    </template>

    <v-spacer></v-spacer>

    </v-toolbar>

    <v-container fluid>
    
        <v-card>

            <v-card-title>Dados</v-card-title>

            <v-card-text>

                <v-form ref="form" v-model="valid" lazy-validation>

                  <v-select
                    v-model="selectPerfil"
                    :items="grupos"
                    item-text="nome_grupo"
                    item-value="id_grupo_usuario"
                    :rules="[v => !!v || 'Obrigatório']"
                    label="Selecione o grupo de acesso"
                    return-object
                    required
                  ></v-select>

                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    :rules="emailRules"
                    :disabled="isEdicao"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="nome_usuario"
                    :counter="50"
                    :rules="nome_usuarioRules"
                    label="Nome do usuario"
                    required
                  ></v-text-field>

                  <!-- <v-text-field
                      v-model="device_id"
                      :counter="14"
                      :rules="idRules"
                      label="Nro do Identificador"
                      required
                  ></v-text-field>

                  <v-text-field
                      v-model="nro_fone"
                      :counter="11"
                      :rules="foneRules"
                      label="Nro Móvel do chip"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="placa_veiculo"
                      :counter="7"
                      :rules="placaRules"
                      label="Placa do veículo de instalação"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="nro_vin"
                      :counter="50"
                      label="Nro do chassi"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="odometro_inicial"
                      :counter="10"
                      label="Odômetro inicial"
                      required
                  ></v-text-field> -->

                  <!-- <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                  ></v-text-field> -->

                  <!--
                  <v-checkbox
                  v-model="checkbox"
                  :rules="[v => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
                  ></v-checkbox> -->

                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='success' @click.native="enviarCadastro()">
                    OK
                </v-btn>
                <v-btn color='error' @click.stop="$router.go(-1)">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      color="success"
    >
      {{ snackbar_text }}

    </v-snackbar>

  </v-container>

</template>

<style>

  .placaInput input{
    text-transform: uppercase
  }

</style>

<script>

import firebase from "firebase"

export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    valid: true,
    email: null,
    nome_usuario: null,
    descricao_grupo: null,
    nome_usuarioRules: [
      v => !!v || 'O none é obrigatório',
      v => (v && v.length <= 50) || 'O nome deve ter no máximo 50 caracteres',
    ],
    emailRules: [
      v => !!v || 'O e-mail é obrigatório',
      v => /.+@.+\..+/.test(v) || 'O formato do e-mail deve ser válido',
    ],
    select: null,
    selectPerfil: null,
    grupos: [
    ],
    perfil: [
      {descricao: "Administrador", id:"adm"},
      {descricao: "Operador", id:"opr"},
    ],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null
  }),
  methods: {
    enviarCadastro() {

      if (this.$refs.form.validate()) {

        let payload = null

        if (this.isEdicao) {

          payload = { id: this.$store.getters.onScreen_user_id,
                      id_cliente: this.selectPerfil.id_cliente, 
                      nome: this.nome_usuario, 
                      id_grupo_usuario: this.selectPerfil.id_grupo_usuario, 
                      email: this.email }

          console.log('UPDATE',payload)

          this.$http
            .post(process.env.VUE_APP_ENDPOINT_ROOT + '/usuario', payload )
            .then( response => {

                this.ins_response = response
                this.snackbar_text = 'Cadastro efetuado'
                this.snackbar=true

                firebase.auth().currentUser.sendEmailVerification()
                .then(() => {
                  this.snackbar_text = 'Email de verificação enviado'
                  console.log('Email enviado')
                  // ...
                });

              })
            .catch(error => console.log('ERRO', error.message))

        } else {

          firebase.auth().createUserWithEmailAndPassword(this.email, '123456')
            .then(data => {
              console.log('FIREBASE',data)
              payload = { id: data.user.uid,
                          id_cliente: this.selectPerfil.id_cliente, 
                          nome: this.nome_usuario, 
                          id_grupo_usuario: this.selectPerfil.id_grupo_usuario, 
                          email: this.email }
              console.log('PAYLOAD', payload)

              this.$http
              .post(process.env.VUE_APP_ENDPOINT_ROOT + '/usuario/', payload )
              .then( response => {

                  this.ins_response = response
                  this.snackbar_text = 'Cadastro efetuado'
                  this.snackbar=true

                  firebase.auth().currentUser.sendEmailVerification()
                  .then(() => {
                    this.snackbar_text = 'Email de verificação enviado'
                    // Email verification sent!
                    // ...
                  });

                })
              .catch(error => console.log('ERRO', error.message))


            })
            .catch(err => {
              this.error = err.message;
              console.log('FIREBASE',err.message)
            });
        
        }

        

      }
    },
    start_load() {

      this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/grupos/" + this.$store.getters.onScreen_adm_cliente_id )
        .then( (response) => {
          for (const key in response.data) {
            this.grupos.push({nome_grupo : response.data[key].nome_cliente +'/'+response.data[key].descricao, 
                              id_grupo_usuario: response.data[key].id_grupo_usuario, 
                              id_cliente: response.data[key].id_cliente})
          }
      });      

      if (this.isEdicao) {

        console.log('EDICAO', this.$store.getters.onScreen_user_id)

        this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/usuario/" + this.$store.getters.onScreen_user_id )
        .then( (response) => {
          console.log('RETORNO DEVICE', response.data.usuario_data[0])
          this.nome_usuario = response.data.usuario_data[0].nome
          this.email = response.data.usuario_data[0].email
          this.selectPerfil = { id_grupo_usuario: response.data.usuario_data[0].id_grupo_usuario,
                                id_cliente: response.data.usuario_data[0].id_cliente }
        });

      }

    }
  },
  computed:{ 
    isEdicao(){return this.$store.getters.onScreen_user_id}
  },
  created() {
    this.start_load()
  },
};

</script>
