<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          {{ $store.getters.onScreen_cliente_nome }} - {{ $store.getters.onScreen_device_id }} - Informe de resolução DTC
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid>
      <v-card>
        <v-card-title>Dados</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            
            <v-text-field
              v-model="descDTC"
              label="DTC resolvido"
              required
              disabled
            ></v-text-field>

            <!--<v-text-field
              v-model="obsAgendamento"
              label="Observação do agendamento"
              required
              disabled
            ></v-text-field> -->

            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="data_execucao"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data_execucao"
                  label="Data da execução"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="data_execucao" scrollable locale="pt-br" :max="max_date_picker">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(data_execucao)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <!-- <v-text-field
              v-model="qtdeKm"
              :rules="kmRules"
              label="Kilometragem da execução"
              required
            ></v-text-field> -->

            <v-text-field
              v-model="obsServico"
              label="Observações"
              required
            ></v-text-field>

            <!--<v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'You must agree to continue!']"
                label="Do you agree?"
                required
                ></v-checkbox> -->

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click.native="enviarCadastro()">
            Gravar
          </v-btn>
          <v-btn color="error" @click.stop="$router.go(-1)">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="success">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<style>
.placaInput input {
  text-transform: uppercase;
}
</style>

<script>
export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    modal: false,
    dados_resolucao_erro: {},
    idServico: '',
    obsAgendamento: '',
    max_date_picker: new Date().toISOString().split('T')[0],
    data_execucao: '',
    valid: true,
    itemAlt: null,
    detail_str: "",
    descDTC: "",
    nomeRules: [
      (v) => !!v || "O nome é obrigatório",
      // v => (v && v.length == 14) || 'O id deve ter 14 caracteres numéricos',
    ],
    qtdeKm: "",
    kmRules: [
      (v) => !!v || "A km é obrigatória",
      (v) => v > 0 || "A km deve ser maior do que 0",
    ],
    obsServico: "",
    selTipo: "",
    itemsTipo: [
      { nome: "Ciclico", cod: "C" },
      { nome: "Unico", cod: "U" },
    ],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
  }),
  methods: {
    enviarCadastro() {

      if (this.$refs.form.validate()) {

        this.payload = { 
          codigo_erro: this.descDTC,
          data: this.data_execucao,
          dados: this.obsServico
        }

        console.log('PAYLOAD', this.payload)
        
        console.log('DEVICE_ID', this.$store.getters.onScreen_device_id)

        this.$http
          .post( process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + this.$store.getters.onScreen_device_id + "/dtc", this.payload )
          .then((response) => {
            this.ins_response = response;
            this.snackbar_text = "Resolução registrada";
            this.snackbar = true;
            this.$router.go(-1);
          })
          .catch((error) => console.log(error));
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    selectItem(device_id) {
      var vm = this;
      this.$store.commit("storeDeviceID", device_id);
      vm.$router.push("/home");
    },
  },
  computed: {},
  mounted() {

    if (this.$store.getters.onScreen_resolucao_erro) {
      this.dados_resolucao_erro = this.$store.getters.onScreen_resolucao_erro;
      this.descDTC    = this.dados_resolucao_erro.cod_erro
    }

  },
};
</script>
