<template>
  
  <v-container fluid>

    <!-- START - Toolbar comandos de página -->
    <v-toolbar>

      <v-app-bar-nav-icon
        @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <!-- <v-toolbar-title><h3>{{this.$route.params.cliente_nome}}</h3></v-toolbar-title> -->
      <v-toolbar-title><h4>Gestão Usuários/Permissões</h4></v-toolbar-title>

      <v-spacer></v-spacer>

    </v-toolbar>
    <!-- END - Toolbar comandos de página -->

    <v-spacer></v-spacer>

    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">

      <v-layout wrap="wrap">

        <v-flex xs12="xs12" md12="md12">

          <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
          >
            <template v-slot:header>
              
              <v-toolbar class="mb-1" elevation="1">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Pesquisar"
                ></v-text-field>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="sortBy"
                    flat
                    solo-inverted
                    hide-details
                    :items="keys"
                    prepend-inner-icon="mdi-magnify"
                    label="Ordenar por"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-btn-toggle v-model="sortDesc" mandatory>
                    <v-btn large depressed color="blue" :value="false">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn large depressed color="blue" :value="true">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
            
            </template>

            <template v-slot:default="props">
              
              <v-row>
                
                <v-col
                  v-for="item in props.items"
                  :key="item.nome"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card
                    v-ripple
                    class="clickable"
                    style="cursor:pointer;"
                    @click.native="selectItem(item.rota, item.params)"
                  >
                    <v-card-text>
                      
                      <v-list-item class="grow">
                          <v-list-item-icon>
                            <v-icon
                              color="gray"
                            >
                              {{item.icone}}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title><h4>{{item.nome}}</h4></v-list-item-title>
                          </v-list-item-content>

                          <!-- <v-spacer></v-spacer> -->

                          <!-- <v-menu auto offset-y>
                          <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" v-on:click.prevent 
                                @click.stop="" 
                                @mousedown.stop="" 
                                @touchstart.stop=""
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                              <v-list-item v-for="(item, i) in menus" :key="i" @click.stop="clickEditMenu()">
                                  <v-list-item-content>
                                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                      </v-menu> -->

                      </v-list-item>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row class="mt-2" align="center" justify="center">
                <span class="grey--text">Itens por pagina</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      text
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span
                  class="mr-4
                grey--text"
                >
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn fab dark color="indigo" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab dark color="indigo" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>

        </v-flex>

      </v-layout>

    </v-container>

  </v-container>
  <!-- cards com produtos : fim -->
</template>

<script>
export default {
  name: "Produtos",
  components: {},
  props: ['cliente_id', "cliente_nome"],
  data: () => ({
    info: null,
    dialogProd: false,
    action_menu_items: [{ title: "Adicionar cliente" }],
    itemsPerPageArray: [4, 8, 12],
    search: '',
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 4,
    sortBy: 'nome',
    keys: [
      'id',
      'nome',
    ],
    items: [],
  }),
  methods: {
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    selectItem(rota, parametros) {
      // var vm = this;
      //this.modal.titulo = card.titulo + " - " + card.subtitulo;
      //this.modal.cor_toolbar = card.cor;
      //console.log("selected", this.$refs);
      // vm.dialogProd = true;
      this.$router.push( { name: rota , params: { cliente_id: parametros }} );
    },
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
  },
  mounted() {
    this.items = [
      {
        id : 1,
        nome: "Grupos de Acesso/Permissões",
        rota: "gestaoGrupoPermissao",
        icone: "mdi-account-multiple"
      },
      {
        id : 2,
        nome: "Usuários",
        rota: "gestaoUsuario",
        icone: "mdi-card-account-details",
        params: this.$store.getters.onScreen_cliente_id
      }
    ]
  },
};
</script>
