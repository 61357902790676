<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          {{ $store.getters.onScreen_cliente_nome }} -
          {{ $store.getters.onScreen_device_id }} - Serviços executados
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid>
      <v-card class="pa-5">
        <xlsx-workbook>
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download :filename="filename">
            <v-btn color="mb-5 primary">
              Download XLSX
            </v-btn>
          </xlsx-download>
        </xlsx-workbook>
        <v-data-table
          :headers="headers"
          :items="services"
          :items-per-page="10"
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="success">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<style>
.placaInput input {
  text-transform: uppercase;
}
</style>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  name: "cadastroDevice",
  components: {
    XlsxWorkbook,
    XlsxDownload,
    XlsxSheet,
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
    sheets: [],
    headers: [
      {
        text: "Serviço",
        value: "descricao_servico",
      },
      {
        text: "Data execução",
        value: "data_execucao",
      },
      {
        text: "Kilometragem execução",
        value: "km_execucao",
      },
      {
        text: "Observações",
        value: "observacoes",
      },
    ],
    services: [],
    filename: null,
  }),
  methods: {
    getNextServices() {
      const deviceId = this.$store.getters.onScreen_device_id;

      this.$http
        .get(
          process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + deviceId + "/agenda"
        )
        .then((response) => {
          const executados = response.data.servicos.executados.map(
            ({
              descricao_servico,
              data_execucao,
              km_execucao,
              observacoes,
            }) => {
              return {
                descricao_servico,
                data_execucao: this.moment(data_execucao).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                km_execucao,
                observacoes,
              };
            }
          );

          this.sheets = [
            {
              name: "Servicos_Executados",
              data: executados,
            },
          ];

          this.services = executados;
        });
    },
    setFileName() {
      const now = new Date();

      const formatted_date = `${now.getUTCDate()}_${now.getUTCMonth() +
        1}_${now.getUTCFullYear()}`;

      this.filename = `Rel_Servicos_Executados_${formatted_date}.xlsx`;
    },
  },
  computed: {},
  mounted() {
    this.setFileName();
    this.getNextServices();
  },
};
</script>
