import Vue from "vue";
import VueRouter from "vue-router";

import store from '../store'

import Produtos from "../views/Produtos.vue";
import Home from "../views/Home.vue";
import Cliente from "../views/Cliente.vue";
import CadastroCliente from "../views/CadastroCliente.vue";
import GestaoUsuario from "../views/GestaoUsuario.vue";
import GestaoGrupoUsuario from "../views/GestaoGrupoUsuario.vue";
import GestaoGrupoPermissao from "../views/GestaoGrupoPermissao.vue";
import CadastroDevice from "../views/CadastroDevice.vue";
import CadastroGrupo from "../views/CadastroGrupo.vue";
import CadastroUsuario from "../views/CadastroUsuario.vue";
import CadastroParametroKM from "../views/CadastroParametroKM.vue";
import DeviceMonitor from "../views/DeviceMonitor.vue";
import HistoricoTracking from "../views/HistoricoTracking.vue";
import ProcessoKM from "../views/Processo_KM.vue";
import ParametrosKM from "../views/Parametros_KM.vue";
import RevisaoDevice from "../views/Revisao_Device.vue";
import ResolucaoErro from "../views/Resolucao_erro.vue";
import Processos from "../views/Processos.vue";
import AgendaDeServicos from "../views/AgendaDeServicos.vue";
import PrimeiroAcesso from "@/components/PrimeiroAcesso.vue";
import RelatorioProximosServicos from '@/views/RelatorioProximosServicos.vue';
import RelatorioServicosExecutados from '@/views/RelatorioServicosExecutados.vue';
import RelatorioCodigoErros from '@/views/RelatorioCodigoErros.vue';
import RelatorioTempoReal from '@/views/RelatorioTempoReal.vue';
import Login from "@/components/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/primeiroacesso",
    name: "PrimeiroAcesso",
    component: PrimeiroAcesso,
    meta: { requiresAuth: true }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: Produtos,
    meta: { requiresAuth: true }
  },
  {
    path: "/cliente",
    name: "cliente",
    component: Cliente,
    meta: { requiresAuth: true }
  },
  {
    path: "/cadastroCliente",
    name: "cadastroCliente",
    component: CadastroCliente,
    meta: { requiresAuth: true }
  },
  {
    path: "/gestaoGrupoUsuario",
    name: "gestaoGrupoUsuario",
    component: GestaoGrupoUsuario,
    meta: { requiresAuth: true }
  },
  {
    path: "/gestaoGrupoPermissao",
    name: "gestaoGrupoPermissao",
    component: GestaoGrupoPermissao,
    meta: { requiresAuth: true }
  },
  {
    path: "/gestaoUsuario",
    name: "gestaoUsuario",
    component: GestaoUsuario,
    meta: { requiresAuth: true }
  },
  {
    path: "/devicemonitor",
    name: "devicemonitor",
    component: DeviceMonitor,
    meta: { requiresAuth: true }
  },
  {
    path: "/cadastroDevice",
    name: "cadastroDevice",
    component: CadastroDevice,
    meta: { requiresAuth: true }
  },
  {
    path: "/cadastroGrupo",
    name: "cadastroGrupo",
    component: CadastroGrupo,
    meta: { requiresAuth: true }
  },
  {
    path: "/cadastroUsuario",
    name: "cadastroUsuario",
    component: CadastroUsuario,
    meta: { requiresAuth: true }
  },
  {
    path: "/cadastroParametroKM",
    name: "cadastroParametroKM",
    component: CadastroParametroKM,
    meta: { requiresAuth: true }
  },
  {
    path: "/processos",
    name: "processos",
    component: Processos,
    meta: { requiresAuth: true }
  },
  {
    path: "/historicoTracking",
    name: "historicoTracking",
    component: HistoricoTracking,
    meta: { requiresAuth: true }
  },
  {
    path: "/processokm",
    name: "processokm",
    component: ProcessoKM,
    meta: { requiresAuth: true }
  },
  {
    path: "/parametroskm",
    name: "parametroskm",
    component: ParametrosKM,
    meta: { requiresAuth: true }
  },
  {
    path: "/revisaodevice",
    name: "revisaodevice",
    component: RevisaoDevice,
    meta: { requiresAuth: true }
  },
  {
    path: "/resolucaoerro",
    name: "resolucaoerro",
    component: ResolucaoErro,
    meta: { requiresAuth: true }
  },
  {
    path: "/agendadeservicos",
    name: "agendadeservicos",
    component: AgendaDeServicos,
    meta: { requiresAuth: true }
  },
  {
    path: "/relatorio/proximos-servicos",
    name: "proximosservicos",
    component: RelatorioProximosServicos,
    meta: { requiresAuth: true }
  },
  {
    path: "/relatorio/servicos-executados",
    name: "servicosexecutados",
    component: RelatorioServicosExecutados
  },
  {
    path: "/relatorio/codigos-erro",
    name: "relatoriocodigoserros",
    component: RelatorioCodigoErros
  },
  {
    path: "/relatorio/tempo-real",
    name: "relatoriotemporeal",
    component: RelatorioTempoReal
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach( (to, from, next)=>{
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
    } else {
      router.replace('login')
    }
  } else {
    next();
  }
});

export default router;
