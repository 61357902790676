<template>
  <v-app id="inspire">

    <v-app-bar app color="indigo" dark>
   
      <v-toolbar-title>

        <div v-if="this.$store.getters.isAuthenticated">
          {{this.$store.getters.onScreen_adm_cliente}}
        </div>
        <div v-else>
          Blanes Remote Service
        </div>

      </v-toolbar-title>
      
      <v-spacer></v-spacer>

      <div v-if="this.$store.getters.isAuthenticated">
        <small>{{this.$store.getters.user}}</small>
      </div>

      <v-btn icon @click="logout" v-if="this.$store.getters.isAuthenticated">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main class="grey lighten-4">
      <transition name="slide-left">
        <router-view></router-view>
      </transition>
    </v-main>

  </v-app>
</template>

<script>

import firebase from "firebase"

export default {
  props: {
    source: String,
  },
  data: () => ({
    isAuthenticated: false
  }),
  methods: {
    logout() {
      firebase.auth().signOut()
      .then( () => {
        console.debug("logging out")

        firebase.auth().signOut()
        .then(() => {
          this.$store.commit('storeUser', null, null)
          this.$store.commit('storeUserToken', null)
          this.$store.commit('storeAdmCliente', null)
          console.debug('STORADO',this.$store.getters.user)
          console.debug('TOKEN',this.$store.getters.token)
          console.debug('AUTH',this.$store.getters.isAuthenticated)
          this.$router.replace('/')
        })
        .catch((error) => {
          console.debug("NOT SIGNED IN", this.login);
          var errorCode = error.code;
          console.debug("CODE", errorCode);
          var errorMessage = error.message;
          console.debug("MESSAGE", errorMessage);
        });
      })
      .catch( (err) => {
        console.debug(err);
      })
    }
  },
  mounted: function() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hide";
  },
  destroyed: function() {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
};
</script>
