<template>
  <v-container id="signinup-form" class="fill-height">

    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="12" md="6" class="">
        <v-card class="evelation-12 card">
          <v-window v-model="step">
            <!--SignIn-->
            <v-window-item :value="1">
              <v-row class="">
                <v-col cols="12" md="12" sm="12" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form class="signup-form-form">
                      <h3 class="text-center display-1 mb-10" :class="`${bgColor}--text`">Primeiro Acesso</h3>
                      <p>Digite uma nova senha</p>
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Senha"
                        name="Senha"
                        :append-icon="viewPass ? 'visibility' : 'visibility_off'"
                        @click:append="() => (viewPass = !viewPass)"
                        :type="viewPass ? 'password' : 'text'"
                        :color="bgColor"
                      />
                      <div class="text-center mt-6">
                        <v-btn
                          type="submit"
                          large
                          :color="bgColor"
                          dark
                          @click="handleSubmit"
                          >Enviar</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                
              </v-row>
            </v-window-item>
            <!--SignUp-->
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        Já se registrou?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Por favor, faça o acesso
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">Acessar</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class=" pt-6 pb-6">
                  <v-card-text>
                    <h1
                      class="text-center display-1 mb-10"
                      :class="`${bgColor}--text`"
                    >
                      Dados de registro
                    </h1>
                    <v-form class="signup-form-form" @submit.prevent="signup">
                      <v-text-field
                        id="username"
                        v-model="username"
                        label="Nome de usuario"
                        name="username"
                        append-icon="person"
                        type="text"
                      />
                      <v-text-field
                        id="email"
                        v-model="email"
                        label="E-mail"
                        name="email"
                        append-icon="email"
                        type="email"
                      />
                      <v-text-field
                        id="password"
                        v-model="password"
                        label="Senha"
                        name="password"
                        append-icon="lock"
                        type="password"
                      />
                      <div class="text-center mt-6">
                        <v-btn type="submit" large :color="bgColor" dark>
                          Registrar</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <!--PW Rest-->
            <v-window-item :value="3">
              <v-row class="fill-height">
                <!-- <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">
                        Already a user?
                      </h1>
                      <h5 class="text-center overline mb-3">
                        Please Sign In
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">Sign In</v-btn>
                    </div>
                  </div>
                </v-col> -->
                <v-col cols="12" md="12" class="">
                  <v-card-text>
                    <v-form class="signup-form-form">
                      <h3
                        class="text-center display-1 mb-12"
                        :class="`${bgColor}--text`"
                      >
                        Nova senha
                      </h3>
                      <v-text-field
                        id="login"
                        v-model="email_cadastrado"
                        label="Email cadastrado"
                        name="login"
                        append-icon="email"
                        type="text"
                        :color="bgColor"
                        class="v-input__icon--double"
                      />
                      <p><small>Se existir um usuario cadastrado no sistema para o email informado, você receberá um link para redefinição da senha</small></p>
                      <div class="text-center mt-6">
                        <v-btn large :color="bgColor" dark @click="sendResetLink"
                          >Enviar link</v-btn>
                        &nbsp;
                        <v-btn large :color="bgColor" dark @click="step = 1">Cancelar</v-btn>

                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" :color="snackbar_color">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>

</template>

<script>
//import Notification from './Notification'

import firebase from "firebase"

export default {
  name: "PrimeiroAcesso",
  components: {
    //Notification
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "indigo",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  async fetch({ store, error }, user) {
    try {
      await store.dispatch("users/signupUser", user);
    } catch (e) {
      error({
        statusCode: 503,
        message: "Unable to sign up user. Please try again later.",
      });
    }
  },
  data: () => ({
    step: 1,
    viewPass: String,
    username: "",
    email: "",
    password: "",
    login: "",
    email_cadastrado: "",
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
    snackbar_color: null
  }),
  methods: {
    handleSubmit(e) {
      
      e.preventDefault();
      
      if (this.password.length > 0) {

        firebase.auth().currentUser.updatePassword(this.password)
        .then(() => {

          console.debug('Senha alterada')

          let payload = [{
                descricao: "PRIMEIRO ACESSO",
                datahora_evento: this.moment().format('MM-DD-YYYY HH:mm:SS')
              }]

          this.$http
          .post(process.env.VUE_APP_ENDPOINT_ROOT + "/usuario/" + firebase.auth().currentUser.uid +"/evento/", payload)
          .then(() => {
            this.$router.push('/produtos')
          }).catch((error) => {
            console.debug('ERROR', error.message)
          });

        }).catch((error) => {
          console.debug('ERROR', error.message)
        });

      }
    },
    signup() {
      this.$auth
        .signup({
          data: {
            user: {
              username: this.username,
              email: this.email,
              password: this.password,
            },
          },
        })
        .catch((e) => {
          this.error = e + "";
        });
    },
    sendResetLink() {
      console.debug('Enviar', this.email_cadastrado)
      firebase.auth().sendPasswordResetEmail(this.email_cadastrado)
      .then(() => {
        this.snackbar_text = 'Email de redefinição de senha enviado'
        this.snackbar_color = "success"
        this.step=1
        this.snackbar=true
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.snackbar_text = errorCode + ' ' + errorMessage
        this.snackbar_color = "error"
        this.snackbar=true
      });
    }
  },
};
</script>

<style scoped lang="scss">
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
#signinup-form {
  max-width: 75rem;
}
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
