<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          {{ $store.getters.onScreen_cliente_nome }} -
          {{ $store.getters.onScreen_device_id }} - Tempo real
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid>
      <v-card class="pa-5">
        <v-row class="pa-5">
          <v-col cols="12" align="center" xs="4">
            <v-form ref="filterForm">
              <v-datetime-picker
                label="Periodo ínicio"
                :rules="dateTimeRules"
                required
                v-model="initDateTime"
              ></v-datetime-picker>
              <v-datetime-picker
                label="Periodo fim"
                :rules="dateTimeRules"
                required
                v-model="endDateTime"
              ></v-datetime-picker>
            </v-form>
          </v-col>
          <v-row cols="12" class="ma-2">
            <v-btn
              color="mb-5 primary mr-2"
              @click="findRealTimeDataFromFilter"
            >
              Buscar
            </v-btn>
            <xlsx-workbook>
              <xlsx-sheet
                :collection="sheet.data"
                v-for="sheet in sheets"
                :key="sheet.name"
                :sheet-name="sheet.name"
              />
              <xlsx-download :filename="filename">
                <v-btn color="mb-5 secondary">
                  Download XLSX
                </v-btn>
              </xlsx-download>
            </xlsx-workbook>
          </v-row>
        </v-row>

        <v-data-table
          :loading="loading"
          :loading-text="loadingText"
          :headers="headers"
          :items="realtime_data"
          :items-per-page="10"
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="success">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<style>
.placaInput input {
  text-transform: uppercase;
}
</style>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  name: "relatorioTempoReal",
  components: {
    XlsxWorkbook,
    XlsxDownload,
    XlsxSheet,
  },
  data: () => ({
    loadingText: "Selecione um período para buscar os dados",
    loading: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
    sheets: [],
    initDateTime: null,
    endDateTime: null,
    headers: [
      {
        text: "Data/hora",
        value: "data_log",
      },
      {
        text: "Consumo instantâneo (km/L)",
        value: "instantaneous_fuel_consumption_rate",
      },
      {
        text: "Rotação do motor (rpm)",
        value: "rpm",
      },
      {
        text: "Velocidade (km/h)",
        value: "vehicle_speed",
      },
      {
        text: "Atuador do Acelerador (%)",
        value: "harsh_acceleration_no",
      },
      {
        text: "Tot. Combus. Consum",
        value: "total_accumulated_fuel_consumption",
      },
      {
        text: "Tot. Milhas",
        value: "total_mileage",
      },
      {
        text: "Percurso Milhas",
        value: "mileage_of_this_single_trip",
      },
      {
        text: "Carga do Motor (%)",
        value: "engine_load_perc",
      },

      {
        text: "Voltagem da bateria",
        value: "battery_voltage",
      },
      {
        text: "Current Fault Code Numbers",
        value: "current_fault_code_numbers",
      },
      {
        text: "Comsumption Volume Trip",
        value: "consumption_volume_trip",
      },
      {
        text: "Média de consumo (km/L)",
        value: "average_fuel_consumption_rate",
      },
      {
        text: "Temperatura do coolant (C)",
        value: "coolant_temperature",
      },
      {
        text: "throttle_opening_width_perc",
        value: "throttle_opening_width_perc",
      },
    ],
    realtime_data: [],
    dateTimeRules: [(v) => (!!v && v !== null) || "Data/hora é obrigatória"],
    filename: null,
    qtd_erros: 0,
  }),
  methods: {
    parseDate(str) {
      return this.moment(new Date(str)).format("YYYY-MM-DD[T]HH:mm:SSZ");
    },
    findRealTimeDataFromFilter() {
      const deviceId = this.$store.getters.onScreen_device_id;

      this.loading = true;

      const url = new URL(
        process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + deviceId + "/realtime"
      );

      url.searchParams.append("init", this.parseDate(this.initDateTime));
      url.searchParams.append("end", this.parseDate(this.endDateTime));

      this.$http.get(url).then((response) => {
        const realtime_data = response.data.map((data) => {
          return {
            ...data,
            data_log: this.moment(data.data_log).format("DD/MM/YYYY HH:mm:ss"),
          };
        });

        if (realtime_data?.length) {
          this.sheets = [
            {
              name: "Tempo_Real",
              data: realtime_data,
            },
          ];
        }

        this.realtime_data = realtime_data;

        this.loading = false;
      });
    },
    setFileName() {
      const now = new Date();

      const formatted_date = `${now.getUTCDate()}_${now.getUTCMonth() +
        1}_${now.getUTCFullYear()}`;

      this.filename = `Rel_Tempo_Real_${formatted_date}.xlsx`;
    },
  },
  mounted() {
    this.setFileName();
  },
};
</script>
