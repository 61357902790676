<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          {{ $store.getters.onScreen_cliente_nome }} -
          {{ $store.getters.onScreen_device_id }} - Códigos de erro
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid>
      <v-card class="pa-5">
        <v-alert type="warning"
          >{{ qtd_erros }} erros DTC's foram recebidos do device desde a
          instalação</v-alert
        >
        <xlsx-workbook>
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download :filename="filename">
            <v-btn color="mb-5 primary">
              Download XLSX
            </v-btn>
          </xlsx-download>
        </xlsx-workbook>
        <v-data-table
          :headers="headers"
          :items="erros"
          :items-per-page="10"
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="success">
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<style>
.placaInput input {
  text-transform: uppercase;
}
</style>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  name: "cadastroDevice",
  components: {
    XlsxWorkbook,
    XlsxDownload,
    XlsxSheet,
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
    sheets: [],
    headers: [
      {
        text: "Código do DTC",
        value: "codigo_dtc",
      },
      {
        text: "Data/hora",
        value: "data_hora",
      },
    ],
    erros: [],
    filename: null,
    qtd_erros: 0,
  }),
  methods: {
    getCodigosErrosDTC() {
      const deviceId = this.$store.getters.onScreen_device_id;

      this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + deviceId + "/dtc")
        .then((response) => {
          const erros = response.data.map(({ erros, data_ts }) => {
            return {
              codigo_dtc: erros,
              data_hora: this.moment(data_ts).format("DD/MM/YYYY HH:mm:ss"),
            };
          });

          this.sheets = [
            {
              name: "Codigos_Erro",
              data: erros,
            },
          ];

          this.qtd_erros = erros.length;
          this.erros = erros;
        });
    },
    setFileName() {
      const now = new Date();

      const formatted_date = `${now.getUTCDate()}_${now.getUTCMonth() +
        1}_${now.getUTCFullYear()}`;

      this.filename = `Rel_Codigo_Erros_DTC_${formatted_date}.xlsx`;
    },
  },
  mounted() {
    this.setFileName();
    this.getCodigosErrosDTC();
  },
};
</script>
