<template>

  <v-container fluid>

    <v-toolbar>

    <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
            mdi-arrow-left
        </v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title><h3>Mapa das operações</h3></v-toolbar-title>

    <v-spacer></v-spacer>

    </v-toolbar>

    <v-container fluid>

        <v-alert
          :value="alert_master_oper"
          dismissible
          elevation='10'
          type="error"
        >Selecione uma operação.</v-alert>
      
        <v-card>

            <!-- <v-card-title>Dados</v-card-title> -->

            <v-card-text>
              <!-- <v-form @submit.prevent="" ref="form" v-model="valid" lazy-validation> -->

                <v-row>
                  <v-col>
                    <v-jstree 
                    :data="tree_data" 
                    :async="loadTree" 
                    :item-events="itemEvents"
                    :allow-transition="false"
                    whole-row 
                    show-checkbox
                    :draggable="true"
                    @item-drag-start="itemDragStart"
                    @item-drag-end="itemDragEnd"
                    @item-drop-before="itemDropBefore"
                    @item-drop="itemDrop"
                    @item-click="itemClick"
                    ref="tree_cliente">
                    </v-jstree>
                    <br>
                    <!-- <v-btn color='primary' @click.native="()"> -->
                    <!-- <v-btn x-small color='primary' @click.native="addChildNode()">
                      Selecione a operação e clique aqui para adicionar
                    </v-btn> -->

                  </v-col>
                </v-row>

              <!-- </v-form> -->
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn small color='primary' @click.native="addChildNode()">
                incluir
              </v-btn>
              <v-btn small color='primary' @click.native="editChildNode()">
                Editar
              </v-btn>
              <v-btn small color='primary' @click.native="editChildNode()">
                Usuários
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small color='error' @click.stop="$router.go(-1)">
                Cancelar
              </v-btn>
            </v-card-actions>

        </v-card>

    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      color="success"
    >
      {{ snackbar_text }}

    </v-snackbar>

    <ConfirmDialog ref="confirm" />

    <v-dialog
      v-model="cliente_dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-card>
        <v-toolbar
          dark
          color="indigo"
        >
          <v-btn
            icon
            dark
            @click="cliente_dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{titulo_cliente_dialog}}</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>
        <v-card>

            <v-card-title>Dados da Operação</v-card-title>

            <v-card-text>
        
                <v-form ref="form_cliente" v-model="valid" lazy-validation>

                <v-row>
                  <v-col
                    class="pa-12"
                    cols="12"
                  >

                  <v-text-field
                      v-model="nome"
                      :counter="50"
                      :rules="nomeRules"
                      label="Nome"
                      required
                  ></v-text-field>

                  </v-col>

                </v-row>

                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <div v-if="this.insercao">
                  <v-btn color='success' @click.native="enviarCadastro()">
                    OK
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn color='success' @click.native="alterarCadastro()">
                    OK
                  </v-btn>
                </div>
                <v-btn color='error' @click.stop="cliente_dialog=false">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>
      </v-card>

    </v-dialog>

  </v-container>

</template>

<style></style>

<script>

import VJstree from 'vue-jstree'
import ConfirmDialog from "@/components/ConfirmDialog"

export default {
  name: "cadastroCliente",
  components: {VJstree,ConfirmDialog},
  data: () => ({

    alert_master_oper: false,

    loadTree: function (oriNode, resolve) {

      function list_to_tree(list) {

        console.log('LISTA',list[0].parent_id)

        var map = {}, node, roots = [], i;
        
        for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i // initialize the map
          list[i].children = [] // initialize the children
        }
        
        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.parent_id !== list[0].parent_id) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]].children.push(node);
          } else {
            roots.push(node)
          }
        }
        return roots
      }

      this.$http
      .get(process.env.VUE_APP_ENDPOINT_ROOT + "/clientes/" + this.$store.getters.onScreen_adm_cliente_id)
      .then( (response) => {

        var complete_tree = response.data.clientes_data.map(function(el) {
          var o = Object.assign({}, el);
          o.opened = true;
          return o;
        })
      
        let str = JSON.stringify(complete_tree);
        str = str.replace(/nome/g, 'text');

        let base_renamed = JSON.parse(str);
        this.tree_data = list_to_tree(base_renamed)
        //this.$refs.tree.initializeData(this.tree_data)

        console.log('TREE DATA',this.tree_data)

        resolve(this.tree_data)

      });

    },

    tree_data: [],
    editingItem: {},
    editingNode: null,

    itemEvents: {
      // mouseover: function () {
      //   console.log('mouseover')
      // },
      // contextmenu: function () {
      //   console.log(arguments[2])
      //   arguments[2].preventDefault()
      //   console.log('contextmenu')
      // }
    },

    dialog: false,
    dialog_text: '',
    dialog_title: '',

    cliente_dialog: false,
    titulo_cliente_dialog: '',

    //   {
    //     "text": "Same but with checkboxes",
    //     "children": [
    //       {
    //         "text": "initially selected",
    //         "selected": true
    //       },
    //       {
    //         "text": "custom icon",
    //         "icon": "fa fa-warning icon-state-danger"
    //       },
    //       {
    //         "text": "initially open",
    //         "icon": "fa fa-folder icon-state-default",
    //         "opened": true,
    //         "children": [
    //           {
    //             "text": "Another node"
    //           }
    //         ]
    //       },
    //       {
    //         "text": "custom icon",
    //         "icon": "fa fa-warning icon-state-warning"
    //       },
    //       {
    //         "text": "disabled node",
    //         "icon": "fa fa-check icon-state-success",
    //         "disabled": true}
    //         ]
    //   },
    //   {
    //     "text": "Same but with checkboxes",
    //     "opened": true,
    //     "children": [
    //       {
    //         "text": "initially selected",
    //         "selected": true
    //       },
    //       {
    //         "text": "custom icon",
    //         "icon": "fa fa-warning icon-state-danger"
    //       },
    //       {
    //         "text": "initially open",
    //         "icon": "fa fa-folder icon-state-default",
    //         "opened": true,
    //         "children": [
    //           {
    //             "text": "Another node"
    //           }
    //         ]
    //       },
    //       {
    //         "text": "custom icon",
    //         "icon": "fa fa-warning icon-state-warning"
    //       },
    //       {
    //         "text": "disabled node",
    //         "icon": "fa fa-check icon-state-success",
    //         "disabled": true
    //       }
    //     ]
    //   },
    //   {
    //     "text": "And wholerow selection"
    //    }
    // ],
    valid: true,
    insercao: false,
    nome: '',
    nomeRules: [
      v => !!v || 'O nome é obrigatório',
      v => (v && v.length < 50) || 'Máximo de 50 caracteres',
    ],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null,
  }),
  methods: {

    itemClick (node) {
      this.editingNode = node
      this.editingItem = node.model
      console.log(node.model.text + ' clicked !')
    },

    itemDragStart (node, item) {
      console.log('NODE', node)
      console.log('ITEM', item)
    },

    itemDragEnd (node) {
      console.log(node.model.text + ' drag end !')
    },

    itemDropBefore (node) {
      console.log(node.model.text + ' drop before !')
    },

    addChildNode() {

      console.log('add child', this.editingItem.id)

      if (this.editingItem.id) {
        this.titulo_cliente_dialog = 'Inclusão de Operação'
        this.insercao = true
        this.cliente_dialog = true
      } else {
        this.alert_master_oper = true
      }

    },

    editChildNode() {

      console.log('edit child', this.editingItem.text)

      if (this.editingItem.id) {
        this.titulo_cliente_dialog = 'Edição de Operação'
        this.nome = this.editingItem.text
        this.cliente_dialog = true
      } else {
        this.alert_master_oper = true
      }

    },

    async itemDrop (node, item, draggedItem) {

      console.log('NODE',node.model.id)
      console.log('DRAGGED', draggedItem.id)
      console.log('ITEM', item)
      this.dialog_title = 'Mudança de nível - ' + draggedItem.text
      this.dialog_text = 'Confirma mudança para ' + node.model.text
      this.dialog = true

      if (
        await this.$refs.confirm.open(
          'Confirma mudança para ' + draggedItem.text,
          "Tem certeza que deseja movimentar para " + node.model.text + " ?"
        )
      ) {
        
        let payload = {
          id_cliente: draggedItem.id, id_pai: node.model.id
        }

        this.$http
        .post(process.env.VUE_APP_ENDPOINT_ROOT + "/cliente/pai", payload)
        .then( (response) => {
          console.log('Mudou', response)
        });

      }

    },

    refreshNode() {
      this.tree_data = [this.$refs.tree_cliente.initializeLoading()]
      this.$refs.tree_cliente.handleAsyncLoad(this.tree_data, this.$refs.tree_cliente)
    },

    enviarCadastro() {

      console.log('enviar cadastro', this.nome)

      if (this.$refs.form_cliente.validate()) {
        this.payload = {nome: this.nome, parent_id: this.editingItem.id}
        this.$http
          .post(process.env.VUE_APP_ENDPOINT_ROOT + '/cliente/', this.payload)
          .then((response) => {
            this.refreshNode()
            this.ins_response = response
            this.snackbar_text = 'Cadastro efetuado'
            this.snackbar=true
            this.cliente_dialog=false
          })
          .catch(error => console.log(error))
      }
    },

    alterarCadastro() {

      console.log('alterar cadastro', this.nome)

      if (this.$refs.form_cliente.validate()) {
        this.payload = {nome: this.nome, parent_id: this.editingItem.id}
        this.$http
          .post(process.env.VUE_APP_ENDPOINT_ROOT + '/cliente/update', this.payload)
          .then((response) => {
            this.refreshNode()
            this.ins_response = response
            this.snackbar_text = 'Alteração efetuada'
            this.snackbar=true
            this.cliente_dialog=false
          })
          .catch(error => console.log(error))
      }
    }

  },
  computed: {
  },
  beforeCreate() {
  },
};

</script>
