<template>

  <v-container fluid>
      
    <v-toolbar>

      <v-app-bar-nav-icon
        @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title><h4>Gestão de grupos/permissões</h4></v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"
            class="ma-2"
            fab
            small
            color="indigo"
            dark
            @click.native="novoDevice"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        Novo Dispositivo
      </v-tooltip> -->

    </v-toolbar>

    <v-spacer></v-spacer>

    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">

      <!-- Area de botoes de controle -->
      <v-layout wrap="wrap">

        <!-- ADICIONAR REGISTRO -->
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click.native="novoGrupo"
        >
          Adicionar grupo
          <v-icon
            right
            dark
          >
            mdi-plus
          </v-icon>
        </v-btn>

      </v-layout>

      <v-layout wrap="wrap">

        <v-flex xs12="xs12" md12="md12">
    
          <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
          >
            <template v-slot:header>
              <v-toolbar class="mb-1" elevation="1">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Pesquisar"
                ></v-text-field>

                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="sortBy"
                    flat
                    solo-inverted
                    hide-details
                    :items="keys"
                    prepend-inner-icon="mdi-sort"
                    label="Ordenar por"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-btn-toggle
                    v-model="sortDesc"
                    mandatory
                  >
                    <v-btn
                      large
                      depressed
                      color="blue"
                      :value="false"
                    >
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="blue"
                      :value="true"
                    >
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id_grupo_usuario"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card 
                      v-ripple 
                      class="clickable"
                      style="cursor:pointer;"
                      color="gray"
                      @click.native="selectItem(item.id_grupo_usuario)"
                  >
                    <v-card-text>
                      
                      <v-list-item class="grow">
                          <v-list-item-icon>
                            <v-icon color="black">mdi-account-multiple</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title><h4>{{ item.descricao }}<br />{{ item.nome_cliente }}</h4></v-list-item-title>
                          </v-list-item-content>

                          <!-- <v-spacer></v-spacer> -->

                          <!-- <v-menu auto offset-y>
                          <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" v-on:click.prevent 
                                @click.stop="" 
                                @mousedown.stop="" 
                                @touchstart.stop=""
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                              <v-list-item v-for="(item, i) in menus" :key="i" @click.stop="clickEditMenu()">
                                  <v-list-item-content>
                                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                        </v-menu> -->

                      </v-list-item>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row
                class="mt-2"
                align="center"
                justify="center"
              >
                <span class="grey--text">Itens por pagina</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      text
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span
                  class="mr-4
                  grey--text"
                >
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                  fab
                  small
                  dark
                  color="indigo"
                  class="mr-1"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  dark
                  color="indigo"
                  class="ml-1"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
            
          </v-data-iterator>
        
        </v-flex>

      </v-layout>

    </v-container>
  
  </v-container>

</template>

<script>
  export default {
    name: "DeviceMonitor",
    components: {},
    data () {
      return {
        itemsPerPageArray: [12, 24, 36],
        itemsPerPage: 12,
        search: '',
        filter: {},
        sortDesc: true,
        page: 1,
        sortBy: 'descricao',
        keys: [
          'descricao',
        ],
        items: [],
        menus: [
        { text:"Editar", route:"/link1", icon: "mdi-home" }
        // { text:"Link 2", route:"/link2", icon: "mdi-note", action:(e)=>{router.push('/link2')} }
        ],
        timer: ''
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      nextPage() {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage() {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage(number) {
        this.itemsPerPage = number
      },
      selectItem(device_id) {
        var vm = this;
        console.log('SELECTED', String(device_id) )
        this.$store.commit('storeGrupoID', String(device_id))
        vm.$router.push('/cadastroGrupo')
      },
      novoGrupo() {
        console.log('Chegou novo')
        this.$store.commit('storeGrupoID', null)
        this.$router.push({ name: "cadastroGrupo"});
      },
      start_load() {

        console.log('CLIENTE',this.$store.getters.onScreen_adm_cliente_id)

        this.$http
          .get(process.env.VUE_APP_ENDPOINT_ROOT + "/grupos/" + this.$store.getters.onScreen_adm_cliente_id)
          .then( (response) => {
            console.log('GRUPOS MONITOR', response.data )
            this.items = response.data.sort((a, b) => (a.descricao < b.descricao) ? 1 : -1) 
          });

      },
      cancelAutoUpdate () {
        clearInterval(this.timer);
      }
    },
    mounted() {
      this.start_load()
      this.timer = setInterval(this.start_load, 30000);
    },
    beforeDestroy() {
      this.cancelAutoUpdate();
    }
  }
</script>