import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import firebase from "firebase/app";
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css'
import * as VueGoogleMaps from 'gmap-vue'
import Geocoder from "@pderas/vue2-geocoder"
import moment from 'moment'
import VueTimeline from "@growthbunker/vuetimeline";
import DatetimePicker from 'vuetify-datetime-picker';

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.prototype.moment = moment;

const { 
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_GOOGLE_API_KEY
} = process.env;

var firebaseConfig = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const accessToken = localStorage.getItem('access_token')
if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

Vue.use(DatetimePicker);

Vue.use(VueGoogleMaps, {
  load: {
    key: VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  },
})

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage:    null, // e.g. 'en'
  defaultMode:        'address', // or 'lat-lng'
  googleMapsApiKey:   VUE_APP_GOOGLE_API_KEY
});

Vue.use(VueTimeline, {
  theme: "light",
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {}
}).$mount('#app')
