<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="cliqueVoltar()">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h4>
          Dispositivo - {{ $store.getters.onScreen_device_id }}
        </h4></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>

    <v-spacer></v-spacer>

    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
      <!-- Area de detalhes do dispositivo -->

      <!-- Area de botoes de controle -->
      <v-layout wrap="wrap">
        <!-- editar dados de cadastro -->
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click.native="editarDados"
        >
          editar dados
          <v-icon right dark>
            mdi-pencil
          </v-icon>
        </v-btn>

        <!-- agenda de serviços -->
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click.native="agendaDeServicos"
        >
          serviços
          <v-icon right dark>
            mdi-wrench
          </v-icon>
        </v-btn>

        <!-- relatorios -->
        <v-menu offset-y class="ma-2 white--text">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              v-bind="attrs"
              v-on="on"
            >
              relatórios
              <v-icon right dark>
                mdi-file-document-multiple
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in reports"
              :key="index"
              @click="$router.push({ name: item.route })"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>

      <template v-if="data_coleta">
        <v-layout wrap="wrap">
          <!-- Alertas do veiculo -->
          <template>
            <v-flex xs12="xs12" md12="md12">
              <v-layout wrap="wrap">
                <v-flex xs12="xs12" md12="md12">
                  <!-- Alerta DTC -->
                  <v-alert
                    class="white--text"
                    color="red"
                    type="error"
                    dense
                    v-for="dtc in dtcs"
                    :key="dtc.revisao"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <strong>Informado DTC pelo veículo</strong> -
                        {{ dtc.data_hora }} - Código(s) recebido(s):
                        <strong>{{ dtc.codigos_DTC }}</strong>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn @click.native="clickResolver(dtc.codigos_DTC)">
                          Resolução
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>

                  <!-- Atingiu km revisão -->
                  <v-alert
                    class="black--text"
                    color="orange"
                    type="error"
                    dense
                    v-for="alerta in alertas"
                    :key="alerta.revisao"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <strong
                          >Veículo atingiu kilometragem para serviço</strong
                        >
                        - {{ alerta.km_troca }} - {{ alerta.nome }}
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          @click.native="
                            clickInformar(
                              alerta.id_servico,
                              alerta.nome,
                              alerta.obs_agenda
                            )
                          "
                        >
                          Informar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>

                  <!-- Quase atingiu km revisão -->
                  <v-alert
                    class="black--text"
                    type="info"
                    color="yellow"
                    dense
                    v-for="aviso in avisos"
                    :key="aviso.revisao"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <strong
                          >Veículo próximo de atingir kilometragem para
                          serviço</strong
                        >
                        - {{ aviso.km_troca }} - {{ aviso.nome }}
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          @click.native="
                            clickInformar(
                              aviso.id_servico,
                              aviso.nome,
                              aviso.obs_agenda
                            )
                          "
                        >
                          Informar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-flex>
          </template>

          <v-flex xs12="xs12" md6="md6">
            <!-- Widgets informativos -->

            <v-layout wrap="wrap">
              <v-flex xs3="xs3">
                <v-card class="text-xs-center" height="100%">
                  <div v-if="this.status_atual == ''">
                    <v-card-title>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-card-title>
                  </div>
                  <v-card-text>
                    <div v-if="this.status_atual != ''">
                      <h4>
                        <strong :class="`${bgColor}--text text--lighten-1`">{{
                          status_atual
                        }}</strong>
                      </h4>
                      <br /><br />
                    </div>
                    Status do dispositivo
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- data dos dados -->
                <v-card class="text-xs-center" height="100%">
                  <div v-if="this.status_atual == ''">
                    <v-card-title>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-card-title>
                  </div>
                  <v-card-text>
                    <div v-if="this.status_atual != ''">
                      <div>
                        <strong>{{ data_heartBeat }}</strong>
                      </div>
                      <br />
                    </div>
                    Último envio do dispositivo
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- data dos dados -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ odometro_formatado }}</h3>
                    </div>
                    <br />
                    Odômetro Total (Km)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ vin_number }}</h3>
                    </div>
                    <br />
                    Chassi (VIN)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ instantaneous_fuel_consumption_rate }}</h3>
                    </div>
                    <br />
                    Consumo instantâneo (km/L)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ battery_voltage }}</h3>
                    </div>
                    <br />
                    Voltagem da bateria
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ rpm }}</h3>
                    </div>
                    <br />
                    Rotação do motor (rpm)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ vehicle_speed }}</h3>
                    </div>
                    <br />
                    Velocidade do veiculo (km/h)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ harsh_acceleration_no }}</h3>
                    </div>
                    <br />
                    Atuador do Acelerador (%)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ engine_load_perc }}</h3>
                    </div>
                    <br />
                    Carga do Motor (%)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ coolant_temperature }}</h3>
                    </div>
                    <br />
                    Temperatura do coolant (C)
                  </v-card-text>
                </v-card>
              </v-flex>

              <!-- <v-flex xs3="xs3">
              <v-card class="text-xs-center" height="100%">
                <v-card-text>
                  <div><h3>{{instantaneous_fuel_consumption_rate}}</h3></div><br />
                  Consumo instantâneo (km/L)
                </v-card-text>
              </v-card>
            </v-flex> -->

              <!-- <v-flex xs3="xs3">
              <v-card class="text-xs-center" height="100%">
                <v-card-text>
                  <div><h3>{{average_fuel_consumption_rate}}</h3></div><br />
                  Média de consumo (km/L)
                </v-card-text>
              </v-card>
            </v-flex> -->

              <!-- <v-flex xs3="xs3">
              <v-card class="text-xs-center" height="100%">
                <v-card-text>
                  <div><h3>{{mileage_of_this_single_trip}}</h3></div><br />
                  Odômetro parcial
                </v-card-text>
              </v-card>
            </v-flex> -->

              <!-- <v-flex xs3="xs3">
              <v-card class="text-xs-center" height="100%">
                <v-card-text>
                  <div><h3>{{total_accumulated_fuel_consumption}}</h3></div><br />
                  Consumo total (L)
                </v-card-text>
              </v-card>
            </v-flex> -->

              <v-flex xs3="xs3">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div>
                      <h3>{{ total_driving_time_in_hours }}</h3>
                    </div>
                    <br />
                    Tempo total de direção (em horas)
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12="xs12">
                <!-- Nro do chassi -->
                <v-card class="text-xs-center" height="100%">
                  <v-card-text>
                    <div><h3>Localização atual</h3></div>
                    <br />
                    {{ endereco_atual }}
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- <template v-if="$store.getters.onScreen_cliente_info.modulo.includes('tracking')"> -->
          <v-flex xs12="xs12" md6="md6">
            <!-- Localizacao atual -->

            <v-card height="100%">
              <v-card-title>
                Localização atual
                <v-spacer></v-spacer>
                <template
                  v-if="
                    $store.getters.onScreen_cliente_info.modulo.includes(
                      'tracking'
                    )
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="indigo"
                        v-bind="attrs"
                        v-on="on"
                        @click.native="trackingHistory()"
                      >
                        <v-icon dark>
                          mdi-map-search-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Histórico de rastreamento</span>
                  </v-tooltip>
                </template>
              </v-card-title>

              <template
                v-if="
                  $store.getters.onScreen_cliente_info.modulo.includes(
                    'tracking'
                  )
                "
              >
                <v-card-text>
                  <v-flex xs12 class="mb-4">
                    <GmapMap
                      :center="centered"
                      :zoom="17"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUi: false,
                      }"
                      map-type-id="roadmap"
                      style="width:100%; height: 300px  "
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @click="center = m.position"
                      />
                    </GmapMap>
                  </v-flex>
                </v-card-text>
              </template>
              <template v-else>
                <v-container>
                  <p>Módulo não contratado</p>
                </v-container>
              </template>
            </v-card>
          </v-flex>
        </v-layout>
      </template>
      <template v-else>
        <v-layout wrap="wrap">
          <v-flex xs12="xs12">
            <v-card class="text-xs-center" height="100%">
              <v-card-text>
                <div class="display-1 mb-1">
                  <strong class="red--text text--lighten-1"
                    >Não há dados de movimentação</strong
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </template>
    </v-container>
  </v-container>
</template>

<script>
const reports = [
  {
    title: "Próximos serviços",
    icon: "mdi-hammer-wrench",
    route: "proximosservicos",
  },
  {
    title: "Serviços executados",
    icon: "mdi-account-wrench",
    route: "servicosexecutados",
  },
  {
    title: "Códigos de erro",
    icon: "mdi-alert-circle",
    route: "relatoriocodigoserros",
  },
  {
    title: "Tempo real",
    icon: "mdi-speedometer",
    route: "relatoriotemporeal",
  },
];

export default {
  data: () => ({
    reports,
    status_atual: "",
    bgColor: null,
    gprs: [],
    alertas: [],
    avisos: [],
    dtcs: [],
    data_coleta: "",
    data_heartBeat: "",
    odometro_formatado: null,
    endereco_atual: "",
    centered: {},
    markers: [],
    vin_number: null,
    timer: "",
    harsh_acceleration_no: "",
    total_accumulated_fuel_consumption: "",
    total_mileage: "",
    mileage_of_this_single_trip: "",
    instantaneous_fuel_consumption_rate: "",
    engine_load_perc: "",
    vehicle_speed: "",
    battery_voltage: "",
    harsh_brake_no: "",
    current_fault_code_numbers: "",
    consumption_volume_trip: "",
    average_fuel_consumption_rate: "",
    coolant_temperature: "",
    throttle_opening_width_perc: "",
    rpm: "",
    total_driving_time_in_hours: "",
    socket: null,
  }),
  methods: {
    showLocationOnTheMap(latitude, longitude) {
      console.log("LATITUDE", latitude);
      console.log("LONGITUDE", longitude);

      var positions = [[latitude, longitude]];

      var positionsDD = [];

      for (var i = 0; i < positions.length; i++) {
        var latDD = parseFloat(
          parseFloat(positions[i][0][0].substr(0, 2)) +
            parseFloat(positions[i][0][0].substr(2, 8)) / 60
        ).toFixed(6);
        var lonDD = parseFloat(
          parseFloat(positions[i][1][0].substr(0, 3)) +
            parseFloat(positions[i][1][0].substr(3, 8)) / 60
        ).toFixed(6);

        latDD = positions[i][0][1] == "S" ? latDD * -1 : latDD;
        lonDD =
          positions[i][1][1] == "W" || positions[i][1][1] == "O"
            ? lonDD * -1
            : lonDD;

        positionsDD.push([latDD, lonDD]);
      }

      this.centered = { lat: parseFloat(latDD), lng: lonDD };
      this.$store.commit("storeMapCenter", this.centered);
      this.markers = [
        {
          position: { lat: parseFloat(latDD), lng: lonDD },
          label: "Carro do Rodrigo",
        },
      ];

      console.log("CENTERED", this.centered);
      console.log("MARKERS", this.markers);
    },
    trackingHistory() {
      this.$router.push({ name: "historicoTracking" });
    },
    clickInformar(id_servico, nome_revisao, obs_agenda) {
      this.$store.commit("storeRevisaoDevice", {
        id: id_servico,
        nome: nome_revisao,
        obs_agenda: obs_agenda,
      });
      this.$router.push({ name: "revisaodevice" });
    },
    clickResolver(cod_erro) {
      this.$store.commit("storeResolucaoErro", { cod_erro: cod_erro });
      this.$router.push({ name: "resolucaoerro" });
    },
    start_load() {
      this.socket = new WebSocket(
        `${process.env.VUE_APP_ENDPOINT_WS}/device/${this.$store.getters.onScreen_device_id}/ws`
      );

      this.socket.onopen = () => {
        console.debug("[WEBSOCKET] conectado");

        this.socket.onmessage = (message) => {
          let status_detail = JSON.parse(message.data);
          this.data_heartBeat = this.moment(
            status_detail.ultima_mensagem,
            "YYYY-MM-DD[T]HH:mm:SS"
          ).format("DD/MM/YYYY HH:mm:SS");

          console.log("REAL TIME", status_detail);

          if (status_detail.status == "ATIVO") {
            this.bgColor = "green";
          } else {
            this.bgColor = "red";
          }

          const possuiDadosGprs = !!status_detail?.gprs_data;

          if (possuiDadosGprs) {
            const {
              latitude_coordinate,
              longitude_coordinate,
              latitude_direction,
              longitude_direction,
            } = status_detail?.gprs_data;

            this.showLocationOnTheMap(
              [latitude_coordinate, latitude_direction],
              [longitude_coordinate, longitude_direction]
            );

            this.$geocoder.setDefaultMode("lat-lng");
            this.$geocoder.send(this.centered, (response) => {
              this.endereco_atual = response.results[0].formatted_address;
            });
            console.log("ENDERECO ATUAL", this.endereco_atual);
          }

          if (status_detail.real_time && status_detail.em_movto) {
            this.harsh_acceleration_no =
              status_detail.real_time.harsh_acceleration_no;
            this.total_accumulated_fuel_consumption =
              status_detail.real_time.total_accumulated_fuel_consumption;
            this.total_mileage = status_detail.real_time.total_mileage;
            this.mileage_of_this_single_trip =
              status_detail.real_time.mileage_of_this_single_trip;
            this.instantaneous_fuel_consumption_rate =
              status_detail.real_time.instantaneous_fuel_consumption_rate;
            this.engine_load_perc = status_detail.real_time.engine_load_perc;
            this.vehicle_speed = status_detail.real_time.vehicle_speed;
            this.battery_voltage = status_detail.real_time.battery_voltage;
            this.harsh_brake_no = status_detail.real_time.harsh_brake_no;
            this.current_fault_code_numbers =
              status_detail.real_time.current_fault_code_numbers;
            this.consumption_volume_trip =
              status_detail.real_time.consumption_volume_trip;
            this.average_fuel_consumption_rate =
              status_detail.real_time.average_fuel_consumption_rate;
            this.coolant_temperature =
              status_detail.real_time.coolant_temperature;
            this.throttle_opening_width_perc =
              status_detail.real_time.throttle_opening_width_perc;
            this.rpm = status_detail.real_time.rpm;
            this.total_driving_time_in_hours =
              status_detail.driving_behaviour.total_driving_time_in_hours;
          } else {
            this.harsh_acceleration_no = "--";
            this.total_accumulated_fuel_consumption = "--";
            this.total_mileage = "--";
            this.mileage_of_this_single_trip = "--";
            this.instantaneous_fuel_consumption_rate = "--";
            this.engine_load_perc = "--";
            this.vehicle_speed = "--";
            this.battery_voltage = "--";
            this.harsh_brake_no = "--";
            this.current_fault_code_numbers = "--";
            this.consumption_volume_trip = "--";
            this.average_fuel_consumption_rate = "--";
            this.coolant_temperature = "--";
            this.throttle_opening_width_perc = "--";
            this.rpm = "--";
            this.total_driving_time_in_hours = "--";
          }

          this.status_atual = status_detail.status;
        };
      };

      this.$http
        .get(
          process.env.VUE_APP_ENDPOINT_ROOT +
            "/device/" +
            this.$store.getters.onScreen_device_id +
            "/gprs"
        )
        .then((response) => {
          console.log(response?.data?.device_data);
          const { dados_gprs } = response?.data?.device_data;

          if (dados_gprs) {
            this.gprs = Object.values(dados_gprs);
            this.odometro_formatado = this.gprs[0] / 1000;
            this.vin_number = this.gprs[6].vin;
            this.data_coleta = this.moment(
              this.gprs[1],
              "YYYY-MM-DD[T]HH:mm:SS"
            ).format("DD/MM/YYYY HH:mm:SS");
            this.showLocationOnTheMap(
              [this.gprs[2], this.gprs[3]],
              [this.gprs[4], this.gprs[5]]
            );
          }

          this.avisos = [];
          this.alertas = [];

          if (Object.keys(response.data.device_data.erros_DTC).length > 0) {
            let date_zone = this.moment(
              response.data.device_data.erros_DTC.data_hora.substring(0, 19),
              "YYYY-MM-DDTHH:mm:ss"
            ).format("DD/MM/YYYY HH:mm:ss");
            this.dtcs = [
              {
                data_hora: date_zone,
                codigos_DTC: response.data.device_data.erros_DTC.codigos_DTC,
              },
            ];
          }

          for (let revisao in response.data.device_data.revisoes) {
            if (response.data.device_data.revisoes[revisao].tipo === "aviso") {
              this.avisos.push(response.data.device_data.revisoes[revisao]);
              console.log(
                "**** AVISOS",
                response.data.device_data.revisoes[revisao]
              );
            } else {
              this.alertas.push(response.data.device_data.revisoes[revisao]);
              console.log(
                "**** ALERTAS",
                response.data.device_data.revisoes[revisao]
              );
            }
          }

          if (dados_gprs) {
            this.$geocoder.setDefaultMode("lat-lng");
            this.$geocoder.send(this.centered, (response) => {
              this.endereco_atual = response.results[0].formatted_address;
            });
          }
          console.debug("ENDERECO ATUAL", this.endereco_atual);

          this.avisos.sort((a, b) => (a.km_troca > b.km_troca ? 1 : -1));
          this.alertas.sort((a, b) => (a.km_troca > b.km_troca ? 1 : -1));
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    agendaDeServicos() {
      this.$router.push({ name: "agendadeservicos" });
    },
    editarDados() {
      this.$router.push({ name: "cadastroDevice" });
    },
    cliqueVoltar() {
      this.$router.go(-1);
    },
  },
  computed: {},
  mounted() {
    this.start_load();
    //this.timer = setInterval(this.start_load, 30000);
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
    this.socket.close(4999, "Conexao encerrada pelo cliente");
  },
};
</script>
