var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('v-toolbar-title',[_c('h3',[_vm._v(" Dispositivo - "+_vm._s(_vm.$store.getters.onScreen_device_id)+" - Rastreamento ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-spacer'),_c('v-container',{staticClass:"pa-4",attrs:{"fluid":"fluid","grid-list-md":"grid-list-md"}},[_c('v-layout',{attrs:{"wrap":"wrap"}},[_c('v-flex',{attrs:{"xs12":"xs12","md12":"md12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"11","align":"center","xs":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecione o período","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"1","align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({nativeOn:{"click":function($event){return _vm.pesquisaTracking(_vm.dates)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('span',[_vm._v("Pesquisar período")])])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"xs12","md12":"md12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-flex',{staticClass:"mb-4",attrs:{"xs12":""}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"450px"},attrs:{"center":_vm.$store.getters.onScreen_map_center,"zoom":17,"options":{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                },"map-type-id":"roadmap"}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false},on:{"click":function($event){return _vm.openInfoWindow(m);}}})}),(_vm.selectedLocation !== null)?_c('gmap-info-window',{attrs:{"position":{ lat: _vm.selectedLocation.position.lat, lng: _vm.selectedLocation.position.lng },"opened":_vm.infoBoxOpen},on:{"closeclick":function($event){_vm.infoBoxOpen = false;}}},[_c('div',{staticClass:"infoWindow",staticStyle:{"width":"300px"}},[_c('h3',{attrs:{"id":"infoWindow-location"}},[_vm._v(_vm._s(_vm.selectedLocation.label))]),_c('h3',{attrs:{"id":"infoWindow-location"}},[_vm._v("Velocidade : "+_vm._s(_vm.selectedLocation.speed)+" km/h")]),_c('h3',{attrs:{"id":"infoWindow-location"}},[_vm._v("Odometro : "+_vm._s(_vm.selectedLocation.odometer)+" km")]),_c('h3',{attrs:{"id":"infoWindow-location"}},[_vm._v("Coletado em : "+_vm._s(_vm.selectedLocation.data_hora))]),_c('button',{on:{"click":function($event){return _vm.closeInfoWindow();}}},[_vm._v("Close")])])]):_vm._e()],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }