<template>
  <v-container fluid>

    <v-toolbar elevation="1">

    <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
            mdi-arrow-left
        </v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title><h4>{{$store.getters.onScreen_cliente_nome}} - Novo Parametro Revisão</h4></v-toolbar-title>

    <v-spacer></v-spacer>

    </v-toolbar>

    <v-container fluid>
    
        <v-card>

            <v-card-title>Dados</v-card-title>

            <v-card-text>
        
                <v-form ref="form" v-model="valid" lazy-validation>

                <v-text-field
                    v-model="nome"
                    :rules="nomeRules"
                    label="Nome"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="qtdeKm"
                    :rules="kmRules"
                    label="Kilometragem"
                    required
                ></v-text-field>

                <v-select
                  v-model="selTipo"
                  :items="itemsTipo"
                  :rules="[v => !!v || 'Tipo é obrigatório']"
                  item-text="nome"
                  item-value="cod"
                  label="Tipo"
                  required
                ></v-select>

                <!--<v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'You must agree to continue!']"
                label="Do you agree?"
                required
                ></v-checkbox> -->

                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='success' @click.native="enviarCadastro()">
                    Gravar
                </v-btn>
                <v-btn color='error' @click.stop="$router.go(-1)">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      color="success"
    >
      {{ snackbar_text }}

    </v-snackbar>

  </v-container>

</template>

<style>

  .placaInput input{
    text-transform: uppercase
  }

</style>

<script>

export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    valid: true,
    itemAlt: null,
    detail_str: '',
    nome: '',
    nomeRules: [
      v => !!v || 'O nome é obrigatório',
      // v => (v && v.length == 14) || 'O id deve ter 14 caracteres numéricos',
    ],
    qtdeKm: '',
    kmRules: [
      v => !!v || 'A km é obrigatória',
      v => (v > 0) || 'A km deve ser maior do que 0',
    ],
    selTipo: '',
    itemsTipo:[{"nome":'Ciclico', "cod":"C"}, {"nome":'Unico', "cod":"U"}],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null
  }),
  methods: {
    enviarCadastro() {

      if (this.$refs.form.validate()) {

        console.log('STORE GET', this.detail_str)

        this.detail_str = {nome: this.nome, km:this.qtdeKm, tipo: this.selTipo}

        console.log('PAYLOAD', this.payload)
        console.log('ENDPOINT', process.env.VUE_APP_ENDPOINT_ROOT)

        if (this.$store.getters.onScreen_km_param) {

          this.payload = {id: this.$store.getters.onScreen_km_param.id, id_cliente: this.$store.getters.onScreen_cliente_id, detail: JSON.stringify( this.detail_str )}

          this.$http
          .put(process.env.VUE_APP_ENDPOINT_ROOT + '/controle/km/', this.payload)
          .then((response) => {
              this.ins_response = response
              this.snackbar_text = 'Cadastro efetuado'
              this.snackbar=true
              this.$router.go(-1)
            })
          .catch(error => console.log(error))

        } else {

          this.payload = {id_cliente: this.$store.getters.onScreen_cliente_id, detail: JSON.stringify( this.detail_str )}

          this.$http
          .post(process.env.VUE_APP_ENDPOINT_ROOT + '/controle/km/', this.payload)
          .then((response) => {
              this.ins_response = response
              this.snackbar_text = 'Cadastro efetuado'
              this.snackbar=true
              this.$router.go(-1)
            })
          .catch(error => console.log(error))

        }

      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    selectItem(device_id) {
      var vm = this;
      this.$store.commit('storeDeviceID', device_id)
      vm.$router.push('/home')
    }
  },
  computed: {
  },
  mounted() {

    if (this.$store.getters.onScreen_km_param) {

      console.log('ALT', this.$store.getters.onScreen_km_param )

      this.nome    = this.$store.getters.onScreen_km_param.detail.nome
      this.qtdeKm  = this.$store.getters.onScreen_km_param.detail.km
      this.selTipo = this.$store.getters.onScreen_km_param.detail.tipo

    }
    
  },

};

</script>
