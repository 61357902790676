<template>
  <v-container fluid>

    <v-toolbar>

    <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
            mdi-arrow-left
        </v-icon>
    </v-app-bar-nav-icon>

    <template v-if="isEdicao">
      <v-toolbar-title><h4>Edição de grupo de permissões</h4></v-toolbar-title>
    </template>
    <template v-else>
      <v-toolbar-title><h4>Novo grupo de permissões</h4></v-toolbar-title>
    </template>

    <v-spacer></v-spacer>

    </v-toolbar>

    <v-container fluid>
    
        <v-card>

            <v-card-title>Dados</v-card-title>

            <v-card-text>

                <v-form ref="form" v-model="valid" lazy-validation>

                  <v-select
                    v-model="select"
                    :items="operacoes"
                    item-text="nome"
                    item-value="id"
                    :rules="[v => !!v || 'Obrigatório']"
                    label="Selecione a operação"
                    return-object
                    required
                  ></v-select>

                  <v-select
                    v-model="selectPerfil"
                    :items="perfil"
                    item-text="descricao"
                    item-value="id"
                    :rules="[v => !!v || 'Obrigatório']"
                    label="Selecione o perfil de acesso"
                    return-object
                    required
                  ></v-select>

                  <v-text-field
                    v-model="descricao_grupo"
                    :counter="50"
                    label="Nome do grupo"
                    required
                  ></v-text-field>                  

                  <!-- <v-text-field
                      v-model="device_id"
                      :counter="14"
                      :rules="idRules"
                      label="Nro do Identificador"
                      required
                  ></v-text-field>

                  <v-text-field
                      v-model="nro_fone"
                      :counter="11"
                      :rules="foneRules"
                      label="Nro Móvel do chip"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="placa_veiculo"
                      :counter="7"
                      :rules="placaRules"
                      label="Placa do veículo de instalação"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="nro_vin"
                      :counter="50"
                      label="Nro do chassi"
                      required
                  ></v-text-field>

                  <v-text-field class='placaInput'
                      v-model="odometro_inicial"
                      :counter="10"
                      label="Odômetro inicial"
                      required
                  ></v-text-field> -->

                  <!-- <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                  ></v-text-field> -->

                  <!--
                  <v-checkbox
                  v-model="checkbox"
                  :rules="[v => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
                  ></v-checkbox> -->

                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='success' @click.native="enviarCadastro()">
                    OK
                </v-btn>
                <v-btn color='error' @click.stop="$router.go(-1)">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      color="success"
    >
      {{ snackbar_text }}

    </v-snackbar>

  </v-container>

</template>

<style>

  .placaInput input{
    text-transform: uppercase
  }

</style>

<script>

export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    valid: true,
    odometro_inicial: '',
    nro_vin: '',
    descricao_grupo: null,
    labelRules: [
      v => !!v || 'O none é obrigatório',
      v => (v && v.length <= 50) || 'O nome deve ter no máximo 50 caracteres',
    ],
    // device_id: '',
    // device_id_atual: '',
    // idRules: [
    //   v => !!v || 'O id do dispositivo é obrigatório',
    //   v => (v && v.length == 14) || 'O id deve ter 14 caracteres numéricos',
    // ],
    // nro_fone: '',
    // foneRules: [
    //   v => !!v || 'O nro móvel é obrigatório',
    //   v => (v && v.length == 11) || 'O nro móvel deve ter 11 caracteres numéricos',
    // ],
    // placa_veiculo: '',
    // placaRules: [
    //   v => !!v || 'O placa do veículo é obrigatória',
    //   v => (v && v.length == 7) || 'A placa deve ter 7 caracteres',
    // ],
    select: null,
    selectPerfil: null,
    operacoes: [
    ],
    perfil: [
      {descricao: "Administrador", id:"adm"},
      {descricao: "Operador", id:"opr"},
    ],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null
  }),
  methods: {
    enviarCadastro() {

      let payload = null

      console.log('SELECT', this.select)

      if (this.$refs.form.validate()) {

        if (this.isEdicao) {
         
          payload = { id_cliente: this.select.id, 
                           descricao: this.descricao_grupo, 
                           id_grupo_usuario: this.$store.getters.onScreen_grupo_id, 
                           perfil: this.selectPerfil.id }

          console.log('MSMO',this.payload)

        } else {
          payload = { id_cliente: this.select.id, 
                           descricao: this.descricao_grupo, 
                           id_grupo_usuario: 0, 
                           perfil: this.selectPerfil.id }
        }


        console.log('PAYLOAD', payload)

        this.$http
          .post(process.env.VUE_APP_ENDPOINT_ROOT + '/grupo/', payload )
          .then((response) => {
              this.ins_response = response
              this.snackbar_text = 'Cadastro efetuado'
              this.snackbar=true
            })
          .catch(error => console.log(error))

      }
    },
    start_load() {

      this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/clientes/" + this.$store.getters.onScreen_adm_cliente_id )
        .then( (response) => {
          console.log( 'RETORNO CLIENTES', response.data.clientes_data )
          this.operacoes = response.data.clientes_data
      });      
      
      if (this.isEdicao) {

        console.log('EDICAO', this.$store.getters.onScreen_grupo_id)
        
        this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/grupo/" + this.$store.getters.onScreen_grupo_id )
        .then( (response) => {

          console.log('RETORNO DEVICE', response.data)
 
          this.select = { id: response.data[0].id_cliente, nome: response.data[0].nome }
          this.descricao_grupo = response.data[0].descricao
          this.selectPerfil = { id: response.data[0].perfil }

        });

      }

    }
  },
  computed:{ 
    isEdicao(){return this.$store.getters.onScreen_grupo_id}
  },
  created() {
    this.start_load()
  },
};

</script>
