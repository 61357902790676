<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title
        ><h3>
          Dispositivo - {{ $store.getters.onScreen_device_id }} - Rastreamento
        </h3></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>

    <v-spacer></v-spacer>

    <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
      <v-layout wrap="wrap">
        <v-flex xs12="xs12" md12="md12">
          <v-card>
            <!-- pesquisa -->

            <v-card-text>
              <v-row class="mx-0">
                <v-col cols="11" align="center" xs="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dates"
                        label="Selecione o período"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1" align="center" justify="center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click.native="pesquisaTracking(dates)"
                      >
                        <v-icon dark>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Pesquisar período</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12="xs12" md12="md12">
          <!-- Mapa -->
          <v-card height="100%">
            <!-- <v-card-title>
              Localização atual
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>
                      mdi-map-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Histórico de rastreamento</span>
              </v-tooltip>
            </v-card-title> -->

            <v-card-text>
              <v-flex xs12 class="mb-4">
                <GmapMap
                  :center="$store.getters.onScreen_map_center"
                  :zoom="17"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                  }"
                  map-type-id="roadmap"
                  style="width:100%; height: 450px  "
                >
                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    @click="openInfoWindow(m);"
                  />
                  <gmap-info-window
                    v-if="selectedLocation !== null"
                    :position="{ lat: selectedLocation.position.lat, lng: selectedLocation.position.lng }"
                    :opened="infoBoxOpen"
                    @closeclick="infoBoxOpen = false;"
                  >
                    <div class="infoWindow" style="width: 300px;">
                      <h3 id="infoWindow-location">{{ selectedLocation.label }}</h3>
                      <h3 id="infoWindow-location">Velocidade : {{ selectedLocation.speed }} km/h</h3>
                      <h3 id="infoWindow-location">Odometro : {{ selectedLocation.odometer }} km</h3>
                      <h3 id="infoWindow-location">Coletado em : {{ selectedLocation.data_hora }}</h3>
                      <button @click="closeInfoWindow();">Close</button>
                    </div>
                  </gmap-info-window>
                </GmapMap>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dates: [],
    menu: false,
    markers: [],
    selectedLocation: null,
    infoBoxOpen: false,
    payload: null,
    center: null
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" a ");
    },
  },
  methods: {
    setGMapsLocation(latitude, longitude) {
      
      //console.log("LATITUDE", latitude);
      //console.log("LONGITUDE", longitude);

      var positions = [[latitude, longitude]];

      var positionsDD = [];

      for (var i = 0; i < positions.length; i++) {
        
        var latDD = parseFloat( parseFloat(positions[i][0][0].substr(0, 2)) + parseFloat(positions[i][0][0].substr(2, 8)) / 60 ).toFixed(6);
        var lonDD = parseFloat( parseFloat(positions[i][1][0].substr(0, 3)) + parseFloat(positions[i][1][0].substr(3, 8)) / 60 ).toFixed(6);

        latDD = positions[i][0][1] == "S" ? latDD * -1 : latDD;
        lonDD = positions[i][1][1] == "W" || positions[i][1][1] == "O" ? lonDD * -1 : lonDD;

        positionsDD.push([parseFloat(latDD), lonDD]);

      }

      return positionsDD

    },
    pesquisaTracking() {

      let url = process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + this.$store.getters.onScreen_device_id + '/tracking'

      //console.log('PESQUISA url', url)

      this.payload = { inicio:this.dates[0], fim: this.dates[1]}

      //console.log('PAYLOAD ', this.payload)

      this.$http
      .post( url, this.payload )
      .then( (response) => {

        //console.log('RESPONSE',response.data)

        let geoPositionsDecimals = []

        response.data.forEach(element => { // convertendo as coordenadas para coordenadas decimais

          //console.log('ELEMENT',element)

          geoPositionsDecimals = this.setGMapsLocation([element['latitude_coordinate'], element['latitude_direction']],[element['longitude_coordinate'], element['longitude_direction']])
          //console.log('GEO',geoPositionsDecimals[0][0]);
          //console.log('GEO',geoPositionsDecimals[0][1]);
          this.markers.push( {position:{ lat: geoPositionsDecimals[0][0], lng: geoPositionsDecimals[0][1] }, 
                                 label: element['cadastro'].label, 
                                 speed: Number(element['speed']*1.852).toFixed(2), 
                                 odometer: element['odometer']/1000, 
                                 data_hora: this.moment(element['data_ts'], "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss")  } )
        })

      });

    },
    openInfoWindow(location) {
      console.log('LOCATION', location);
      this.selectedLocation = location;
      this.infoBoxOpen = true;
    },
    closeInfoWindow() {
      this.infoBoxOpen = false;
    }
  },
  created() {
  },
};
</script>
