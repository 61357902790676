import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    onScreen_adm_cliente: null,
    onScreen_adm_cliente_id: null,
    onScreen_cliente_nome: null,
    onScreen_cliente_id: null,
    onScreen_cliente_info: null,
    onScreen_cliente_nivel: null,
    onScreen_device_id: null,
    onScreen_grupo_id: null,
    onScreen_user_id: null,
    onScreen_map_center: null,
    onScreen_km_param: null,
    onScreen_revisao_device: {},
    onScreen_resolucao_erro: {},
  },
  mutations: {
    storeRevisaoDevice (state, dados_revisao_device) {
      state.onScreen_revisao_device = dados_revisao_device
    },
    storeResolucaoErro (state, dados_resolucao_erro) {
      state.onScreen_resolucao_erro = dados_resolucao_erro
    },
    storeClienteKmParam (state, km_param) {
      state.onScreen_km_param = km_param
    },
    storeClienteNome (state, cliente_nome) {
      state.onScreen_cliente_nome = cliente_nome
    },
    storeAdmCliente (state, cliente_nome) {
      state.onScreen_adm_cliente = cliente_nome
    },
    storeAdmClienteId (state, cliente_id) {
      state.onScreen_adm_cliente_id = cliente_id
    },
    storeClienteID (state, cliente_id) {
      state.onScreen_cliente_id = cliente_id
    },
    storeClienteInfo (state, cliente_info) {
      state.onScreen_cliente_info = cliente_info
    },
    storeClienteNivel (state, cliente_nivel) {
      state.onScreen_cliente_nivel = cliente_nivel
    },
    storeDeviceID (state, device_id) {
      state.onScreen_device_id = device_id
    },
    storeGrupoID (state, grupo_id) {
      state.onScreen_grupo_id = grupo_id
    },
    storeUserID (state, usuario_id) {
      state.onScreen_user_id = usuario_id
    },
    storeMapCenter (state, centerCoords) {
      state.onScreen_map_center = centerCoords
    },
    authUser (state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
    },
    storeUser (state, user, idToken) {
      state.user = user,
      state.idToken = idToken
    },
    storeUserToken (state, idToken) {
      state.idToken = idToken
    },
    clearAuthData (state) {
      state.idToken = null
      state.userId = null
    }
  },
  actions: {
    setLogoutTimer ({commit}, expirationTime) {
      setTimeout(() => {
        commit('clearAuthData')
      }, expirationTime * 1000)
    },
    logout ({commit}) {
      commit('clearAuthData')
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
    },
    fetchUser ({commit, state}) {
      if (!state.idToken) {
        return
      }
      commit('storeUser', '')
    }
  },
  modules: {
  },
  getters: {
    user (state) {
      return state.user
    },
    token (state) {
      return state.idToken
    },
    isAuthenticated (state) {
      return state.idToken !== null
    },
    onScreen_cliente_nome: state => state.onScreen_cliente_nome,
    onScreen_adm_cliente_id: state => state.onScreen_adm_cliente_id,
    onScreen_adm_cliente: state => state.onScreen_adm_cliente,
    onScreen_cliente_id: state => state.onScreen_cliente_id,
    onScreen_cliente_info: state => state.onScreen_cliente_info,
    onScreen_cliente_nivel: state => state.onScreen_cliente_nivel,
    onScreen_device_id: state => state.onScreen_device_id,
    onScreen_grupo_id: state => state.onScreen_grupo_id,
    onScreen_user_id: state => state.onScreen_user_id,
    onScreen_map_center: state => state.onScreen_map_center,
    onScreen_km_param: state => state.onScreen_km_param,
    onScreen_revisao_device: state => state.onScreen_revisao_device,
    onScreen_resolucao_erro: state => state.onScreen_resolucao_erro
  },
});
