<template>
  <v-container fluid>

    <v-toolbar>

    <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
            mdi-arrow-left
        </v-icon>
    </v-app-bar-nav-icon>

    <template v-if="isEdicao">
      <v-toolbar-title><h4>{{$store.getters.onScreen_cliente_nome}} - Edição de dispositivo</h4></v-toolbar-title>
    </template>
    <template v-else>
      <v-toolbar-title><h4>{{$store.getters.onScreen_cliente_nome}} - Novo dispositivo</h4></v-toolbar-title>
    </template>

    <v-spacer></v-spacer>

    </v-toolbar>

    <v-container fluid>
    
        <v-card>

            <v-card-title>Dados</v-card-title>

            <v-card-text>
        
                <v-form ref="form" v-model="valid" lazy-validation>

                  <v-text-field
                    v-model="label_device"
                    :counter="50"
                    label="Nome do veiculo"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="device_id"
                    :counter="14"
                    :rules="idRules"
                    label="Nro do Identificador"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="nro_fone"
                    :counter="11"
                    :rules="foneRules"
                    label="Nro Móvel do chip"
                    required
                ></v-text-field>

                <v-text-field class='placaInput'
                    v-model="placa_veiculo"
                    :counter="7"
                    :rules="placaRules"
                    label="Placa do veículo de instalação"
                    required
                ></v-text-field>

                <v-text-field class='placaInput'
                    v-model="nro_vin"
                    :counter="50"
                    label="Nro do chassi"
                    required
                ></v-text-field>

                <v-text-field class='placaInput'
                    v-model="odometro_inicial"
                    :counter="10"
                    label="Odômetro inicial"
                    required
                ></v-text-field>

                <!-- <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                ></v-text-field> 

                <v-select
                v-model="select"
                :items="items"
                :rules="[v => !!v || 'Item is required']"
                label="Item"
                required
                ></v-select>

                <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'You must agree to continue!']"
                label="Do you agree?"
                required
                ></v-checkbox> -->

                </v-form>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='success' @click.native="enviarCadastro()">
                    OK
                </v-btn>
                <v-btn color='error' @click.stop="$router.go(-1)">
                    Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>

    </v-container>

    <!-- Dialogo de insercao -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      color="success"
    >
      {{ snackbar_text }}

    </v-snackbar>

  </v-container>

</template>

<style>

  .placaInput input{
    text-transform: uppercase
  }

</style>

<script>

export default {
  name: "cadastroDevice",
  components: {},
  data: () => ({
    valid: true,
    odometro_inicial: '',
    nro_vin: '',
    label_device: '',
    labelRules: [
      v => !!v || 'O none é obrigatório',
      v => (v && v.length <= 50) || 'O nome deve ter no máximo 50 caracteres',
    ],
    device_id: '',
    device_id_atual: '',
    idRules: [
      v => !!v || 'O id do dispositivo é obrigatório',
      v => (v && v.length == 14) || 'O id deve ter 14 caracteres numéricos',
    ],
    nro_fone: '',
    foneRules: [
      v => !!v || 'O nro móvel é obrigatório',
      v => (v && v.length == 11) || 'O nro móvel deve ter 11 caracteres numéricos',
    ],
    placa_veiculo: '',
    placaRules: [
      v => !!v || 'O placa do veículo é obrigatória',
      v => (v && v.length == 7) || 'A placa deve ter 7 caracteres',
    ],
    ins_response: null,
    payload: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: null
  }),
  methods: {
    enviarCadastro() {

      if (this.$refs.form.validate()) {

        if (this.isEdicao) {

          console.log('ID', this.$store.getters.onScreen_device_id)
          console.log('NOVO ID', this.device_id)

          if ( this.$store.getters.onScreen_device_id !== this.device_id ) {

            console.log('NOVO ID')

            this.payload = {id: this.$store.getters.onScreen_device_id, id_cliente: this.$store.getters.onScreen_cliente_id, new_id: this.device_id,
                          cadastro: JSON.stringify({label:this.label_device, placa: this.placa_veiculo, 
                                                    nro_movel: this.nro_fone, odometro_inicial: this.odometro_inicial, vin: this.nro_vin})}

          } else {

            console.log('MESMO ID')

            this.payload = {id: this.device_id, id_cliente: this.$store.getters.onScreen_cliente_id, new_id: 0,
                          cadastro: JSON.stringify({label:this.label_device, placa: this.placa_veiculo, 
                                                    nro_movel: this.nro_fone, odometro_inicial: this.odometro_inicial, vin:this.nro_vin})}

          }          

          console.log('PAYLOAD', this.payload)
          console.log('ENDPOINT', process.env.VUE_APP_ENDPOINT_ROOT)

          this.$http
            .put(process.env.VUE_APP_ENDPOINT_ROOT + '/device/', this.payload)
            .then((response) => {
                this.ins_response = response
                this.snackbar_text = 'Cadastro efetuado'
                this.snackbar=true
              })
            .catch(error => console.log(error))

        } else {

          this.payload = {id: this.device_id, id_cliente: this.$store.getters.onScreen_cliente_id, new_id: 0,
                          cadastro: JSON.stringify({label:this.label_device, placa: this.placa_veiculo, 
                                                    nro_movel: this.nro_fone, odometro_inicial: this.odometro_inicial, vin:this.nro_vin})}

          console.log('PAYLOAD', this.payload)
          console.log('ENDPOINT', process.env.VUE_APP_ENDPOINT_ROOT)

          this.$http
            .post(process.env.VUE_APP_ENDPOINT_ROOT + '/device/', this.payload)
            .then((response) => {
                this.ins_response = response
                this.snackbar_text = 'Cadastro efetuado'
                this.snackbar=true
              })
            .catch(error => console.log(error))

        }

      }
    },
    start_load() {

      if (this.isEdicao) {
        
        this.$http
        .get(process.env.VUE_APP_ENDPOINT_ROOT + "/device/" + this.$store.getters.onScreen_device_id )
        .then( (response) => {

         console.log('RETORNO DEVICE', response.data.device_data[0])
 
         this.label_device = response.data.device_data[0].cadastro.label
         this.nro_fone = response.data.device_data[0].cadastro.nro_movel
         this.placa_veiculo = response.data.device_data[0].cadastro.placa
         this.odometro_inicial = response.data.device_data[0].cadastro.odometro_inicial
         this.device_id = response.data.device_data[0].id
         this.nro_vin = response.data.device_data[0].cadastro.vin

        });

      }

    }
  },
  computed:{ 
    isEdicao(){return this.$store.getters.onScreen_device_id} 
  },
  created() {
    this.start_load()
  },
};

</script>
